import { useCallback, useMemo } from 'react'
import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client'
import { useApiCall } from '@jarvis/react-portal-addons'
import { ActiveUser, User } from '../interfaces/manageUsersInterface'
import { OrgSelectorType, AuthProviderType, TranslatorFunctionType } from '../types/shell'
import { Stack } from '@jarvis/web-stratus-client'
import { PermissionSet, getRoles } from '../utils/permissionSet'
import { getl10n } from '../utils/localizationMap'

export type FetchOptions = {
  shouldFetchActiveUser?: boolean
}

type UseUsersApiCallProps = {
  authProvider: AuthProviderType
  stack: Stack
  orgSelector: OrgSelectorType
  activeUser?: User
  permissionSet?: PermissionSet
  rolesDataSource: string
  t: TranslatorFunctionType
  useOrgedAuthProvider?: boolean
  locale: string
}

const useUsersApiCall = ({
  authProvider,
  stack,
  orgSelector,
  activeUser,
  permissionSet,
  rolesDataSource,
  t,
  useOrgedAuthProvider,
  locale
}: UseUsersApiCallProps) => {
  const client = useMemo(
    () => new UserTenantMgtSvcClient(stack, authProvider.createOrgedAuthProvider()),
    [stack, authProvider]
  )
  const externalUserClient = useMemo(
    () =>
      new UserTenantMgtSvcClient(stack, useOrgedAuthProvider ? authProvider.createOrgedAuthProvider() : authProvider),
    [stack, useOrgedAuthProvider, authProvider]
  )

  const fetchActiveUser = useCallback(
    async ({ shouldFetchActiveUser }: FetchOptions): Promise<ActiveUser> => {
      if (shouldFetchActiveUser || !activeUser) {
        return await client.getCurrentActiveUser().then(({ data }) => data)
      }

      return activeUser
    },
    [activeUser, client]
  )

  const fetchPermissionSet = useCallback(
    async (tenantId) => {
      if (rolesDataSource === 'api') {
        return await getRoles(stack, authProvider, tenantId, locale)
      }

      if (permissionSet.length === 0) {
        throw new Error(`PermissionSet not initialized correctly in MFE properties. PermSet: ${permissionSet}`)
      }

      const newPermissionSet = [...permissionSet]
      permissionSet.forEach((ps) => {
        if (!ps.label && ps.labelKey) {
          ps.label = t(getl10n(ps.labelKey))
        }
      })

      return newPermissionSet
    },
    [authProvider, stack, permissionSet, t, locale, rolesDataSource]
  )

  const fetch = useCallback(
    async ({ shouldFetchActiveUser }: FetchOptions = {}) => {
      const tenantResourceId = orgSelector.getCurrentCustomer() || orgSelector.getOrgTenantId()
      const maxResult = 0
      const permissionSetPromise = fetchPermissionSet(tenantResourceId)
      const activeUserPromise = fetchActiveUser({ shouldFetchActiveUser })
      const usersPromise = client.getUserWithTenantList(tenantResourceId, maxResult).then(({ data }) =>
        data.resourceList
          .filter((item) => item.userTenantDetail.tenantResourceId !== item.user?.idpId)
          .map((item) => {
            const user: User = {
              email: item.user.email?.value,
              familyName: item.user.familyName,
              givenName: item.user.givenName,
              resourceId: item.user.resourceId,
              roleLabel: '',
              roleName: item.userTenantDetail.tenantType,
              rolePermId: item.userTenantDetail.rolePermId,
              state: item.userTenantDetail.state,
              tenantResourceId: item.userTenantDetail.tenantResourceId
            }
            return user
          })
      )
      const externalUsersPromise = externalUserClient
        .getExternalUserList(tenantResourceId, maxResult)
        .then(({ data }) =>
          data.resourceList.map((item) => {
            const user: User = {
              email: item.email.value,
              familyName: '',
              givenName: '',
              resourceId: item.resourceId,
              roleLabel: '',
              roleName: '',
              rolePermId: item.rolePermId,
              state: item.state,
              tenantResourceId: item.tenantResourceId
            }
            return user
          })
        )
        .catch((err) => {
          if (err?.response?.status === 404 || err?.response?.status === 400) {
            return []
          }
          throw err
        })

      const [userList, externalUsers, permissionSet, activeUser] = await Promise.all([
        usersPromise,
        externalUsersPromise,
        permissionSetPromise,
        activeUserPromise
      ])

      const users = [...userList, ...externalUsers]

      return {
        activeUser,
        users,
        permissionSet
      }
    },
    [client, fetchActiveUser, fetchPermissionSet, orgSelector, externalUserClient]
  )

  return useApiCall({
    fetch,
    init: true
  })
}

export default useUsersApiCall
