import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 1px none transparent;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${primitives.color.gray2};
`
