import { PermissionSets } from '@jarvis/web-organization/dist/src/index'

export type PermissionSetProps = {
  rolePermId?: string
  value?: string
  labelKey?: string
  label?: string
  show?: boolean
  type?: string
  uuid?: string
  name?: string
}

export type PermissionSet = Array<PermissionSetProps>

// Roles and permission sets are syonymous
export const getRoles = async (stack, authProvider, tenantId, locale): Promise<PermissionSet> => {
  const roles = []
  const permClient = new PermissionSets(stack, authProvider)
  const permClientResponse = await permClient.getPermissionSets(tenantId, locale)

  permClientResponse.contents?.forEach((permSet) => {
    roles.push({ label: permSet.displayName, rolePermId: permSet.uuid, name: permSet.name })
  })

  return roles
}

export const getSelectablePermissionSet = (permissionSet) => permissionSet.filter((ps) => ps.show !== false)

export const getPermissionSetById = (permissionSet, rolePermId) =>
  permissionSet.find((ps) => ps.rolePermId === rolePermId)
