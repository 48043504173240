import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import useRootContext from '../../contexts/Root/useRootContext'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import { mergel10nMap } from '../../utils/localizationMap'
import { initUserListColumns } from '../../utils/userListColumns'
import { PermissionSet } from '../../utils/permissionSet'
import useUsersApiCall from '../../hooks/useUsersApiCall'
import { ActiveUser, User, DateAndTime } from '../../interfaces/manageUsersInterface'
import { applyCustomRoleAndStatusNames } from '../../utils/customRoles'
import { getDateAndTime } from '../../utils/commonMethods'
import ManageUsers from '../ManageUsers'
import InviteUsers from '../InviteUsers'
import NavigationArea from '../NavigationArea'
import { Container } from './styles'
import { CustomBreadcrumbType } from './types'
import { HeaderBanner } from '../../shared-components/Header'

const App = () => {
  const [activeUser, setActiveUser] = useState<ActiveUser>()
  const [users, setUsers] = useState<User[]>([])
  const [dateAndTime, setDateAndTime] = useState<DateAndTime>(null)
  const [permissionSet, setPermissionSet] = useState<PermissionSet>([])
  const [customBreadcrumbs, setCustomBreadcrumbs] = useState<CustomBreadcrumbType[]>([])
  const { shell, stack } = useRootContext()
  const { authProvider, localization, orgSelector, navigation } = shell
  const { t } = localization.useReactTranslatorHook()
  const locale = localization.locale

  const {
    displayBreadcrumb,
    permissionSets,
    manageUsers: manageUsersPreferences,
    localizationKeyMapping,
    permissionSet: permissionSetPreference
  } = usePreferencesContext()
  const { table: tablePreferences, useOrgedAuthProvider, useGlobalHeader } = manageUsersPreferences || {}
  const rolesDataSource = permissionSets?.dataSource
  const userListColumns = useMemo(
    () => initUserListColumns(tablePreferences?.userListColumns, t),
    [tablePreferences, t]
  )
  mergel10nMap(localizationKeyMapping)

  const usersCall = useUsersApiCall({
    authProvider,
    stack,
    orgSelector,
    permissionSet: permissionSetPreference,
    rolesDataSource,
    t,
    useOrgedAuthProvider,
    locale
  })

  useEffect(() => {
    if (usersCall.pending || usersCall.error || !usersCall.data) {
      return
    }
    const { activeUser, users, permissionSet } = usersCall.data
    setUsers(applyCustomRoleAndStatusNames(permissionSet, users))
    setPermissionSet(permissionSet)
    setActiveUser(activeUser)
    setDateAndTime(getDateAndTime())
  }, [usersCall.pending, usersCall.error, usersCall.data])

  const isLoading = usersCall.pending
  const hasError = usersCall.error
  const forceFetch = useCallback(() => {
    setDateAndTime(null)
    usersCall.forceFetch()
  }, [usersCall])
  useEffect(() => {
    const unregister = navigation.listen((location) => {
      if (location.pathname === '/users' && !isLoading) {
        forceFetch()
      }
    })

    return unregister
  }, [navigation, forceFetch, isLoading])

  const childrenProps = {
    activeUser,
    dateAndTime,
    hasError,
    retrieveUsers: forceFetch,
    users,
    permissionSet,
    userListColumns,
    existingUsers: users,
    isLoading,
    setCustomBreadcrumbs,
    useGlobalHeader,
    rolesDataSource
  }

  return (
    <>
      {useGlobalHeader && <HeaderBanner />}
      <Container data-testid="container" paddingTop={!displayBreadcrumb} mobilePaddingTop={useGlobalHeader}>
        {displayBreadcrumb && <NavigationArea breadcrumbsItems={customBreadcrumbs} />}

        <Router history={navigation}>
          <Switch>
            <Route path="*/users/invite">
              <InviteUsers {...childrenProps} />
            </Route>
            <Route path="*/users">
              <ManageUsers {...childrenProps} />
            </Route>
          </Switch>
        </Router>
      </Container>
    </>
  )
}

export default App
