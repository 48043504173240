import styled, { css, keyframes } from 'styled-components'
import primitives from '@veneer/primitives'
import { Button, TextArea as VeneerTextArea } from '@veneer/core'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${primitives.layout.size5}px;
  padding: ${primitives.layout.size6}px;
`

export const AddButton = styled(Button)`
  && {
    min-width: ${({ small }) => (small ? '66px' : '112px')};
    align-self: flex-end;
  }
`

export const SubInfo = styled.div`
  display: flex;
  margin-top: 2px;

  > svg {
    vertical-align: top;
    color: ${({ theme }) => theme.color.foreground.light};
    flex-shrink: 0;
    margin-right: 0.25em;
  }
`

const shake = keyframes`
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
`
const shakeAnimation = css`
  ${shake} 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
`

export const TextArea = styled(VeneerTextArea)`
  animation: ${({ shake }) => (shake ? shakeAnimation : '')};
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
`
