import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ContextualMenu, MenuItem, MenuList } from '@veneer/core'
import { ContextualToolbar } from '@jarvis/react-portal-addons'
import { getl10n } from '../../../utils/localizationMap'
import { UserListConstants } from '../../../utils/userListColumns'
import { disableTitleOnContextualSvgIcon } from '../../../utils/commonMethods'
import useRootContext from '../../../contexts/Root/useRootContext'
import usePreferencesContext from '../../../contexts/Preferences/usePreferencesContext'
import { ActionSelect, FooterLeadingArea, FooterTrailingArea, FooterContent } from './styles'
import { UserData } from '../../../interfaces/manageUsersInterface'

type UserContextualFooterProps = {
  'data-testid'?: string
  deleteScopeForUser?: boolean
  onAssignRole?: () => void
  onCancel?: () => void
  onDelete?: () => void
  onResendInvitation?: () => void
  selectedItems?: Array<UserData>
  writeScopeForAssignRole?: boolean
  writeScopeForInvite?: boolean
}

const UserContextualFooter = ({
  'data-testid': dataTestId,
  deleteScopeForUser,
  onAssignRole,
  onCancel,
  onDelete,
  onResendInvitation,
  selectedItems,
  writeScopeForAssignRole,
  writeScopeForInvite
}: UserContextualFooterProps) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const { manageUsers: manageUsersPreferences } = usePreferencesContext()
  const { contextualToolbar: contextualToolbarPreferences, table: tablePreferences } = manageUsersPreferences || {}
  const { backgroundColor, displaySelect } = contextualToolbarPreferences || {}
  const { displayFullContextualMenu, hideSolutionsColumn } = tablePreferences || {}

  const [selectValues, setSelectValues] = useState<Array<string>>(
    !deleteScopeForUser ? [''] : [UserListConstants.removeUser]
  )
  const hasActiveStatus = selectedItems.filter((item) => {
    return item.state === 'Active'
  })
  const hasExpiredStatus = selectedItems.filter((item) => {
    return item.state === 'Expired'
  })

  const numSelectedItems = selectedItems.length

  useEffect(() => {
    disableTitleOnContextualSvgIcon()
  }, [])

  useEffect(() => {
    if (hasActiveStatus.length > 0 && displayFullContextualMenu) {
      setSelectValues([UserListConstants.removeUser])
    }
  }, [displayFullContextualMenu, hasActiveStatus.length])

  const contextualFooterOptions = useMemo(() => {
    if (displayFullContextualMenu) {
      const disableResendInvitation = hasActiveStatus.length > 0
      const footerOptions = [
        {
          label: t(getl10n('actionAssignRole')),
          value: UserListConstants.assignRole
        },
        {
          label: t(getl10n('actionAssignSolutions')),
          value: 'assignSolutions',
          disabled: true
        },
        {
          label: t(getl10n('actionAssignResendInvitation')),
          value: UserListConstants.resendInvitation,
          disabled: disableResendInvitation
        },
        {
          label: t(getl10n('actionRemoveUser')),
          value: UserListConstants.removeUser
        }
      ]

      return footerOptions.filter((option) => {
        return !(option.value === 'assignSolutions' && hideSolutionsColumn)
      })
    }

    return [
      {
        value: UserListConstants.resendInvitation,
        label: t(getl10n('actionAssignResendInvitation')),
        disabled: !writeScopeForInvite || hasExpiredStatus.length > 0 || hasActiveStatus.length > 0
      },
      {
        label: t(getl10n('actionAssignRole')),
        value: UserListConstants.assignRole,
        disabled: !writeScopeForAssignRole
      },
      {
        label: t(getl10n('actionRemoveUser')),
        value: UserListConstants.removeUser,
        disabled: !deleteScopeForUser
      }
    ]
  }, [
    deleteScopeForUser,
    displayFullContextualMenu,
    hasActiveStatus.length,
    hasExpiredStatus.length,
    hideSolutionsColumn,
    t,
    writeScopeForAssignRole,
    writeScopeForInvite
  ])

  const handleBtnAction = useCallback(
    (value) => {
      switch (value) {
        case UserListConstants.removeUser:
          onDelete()
          break
        case UserListConstants.assignRole:
          onAssignRole()
          break
        case 'assignSolutions':
          break
        case UserListConstants.resendInvitation:
          onResendInvitation()
          break
        default:
          break
      }
    },
    [onAssignRole, onDelete, onResendInvitation]
  )

  const items = useMemo(
    () => contextualFooterOptions.map((item) => <MenuItem key={item.label} {...item} />),
    [contextualFooterOptions]
  )

  if (displaySelect) {
    return (
      <ContextualToolbar
        data-testid={dataTestId}
        backgroundColor={backgroundColor}
        boxShadow
        show={numSelectedItems > 0}
      >
        <FooterContent>
          <FooterTrailingArea>
            <Button appearance="secondary" data-testid="cancel-button" id="cancelBtn" onClick={() => onCancel()}>
              {t(getl10n('contextualFooterCancel'))}
            </Button>

            <label className="contextual-footer-items-label" data-testid="contextual-footer-items-label">
              {numSelectedItems == 1
                ? t(getl10n('contextualFooterItemSelect'), { number: numSelectedItems })
                : t(getl10n('contextualFooterItemsSelect'), { number: numSelectedItems })}
            </label>
          </FooterTrailingArea>
          <FooterLeadingArea>
            <ActionSelect
              clearIcon={false}
              data-testid="select-footer-batch-operation"
              id="select-footer-batch-operation"
              label={selectValues ? `${t(getl10n('selectActionLabel'))}` : ''}
              onChange={({ value: v }) => {
                setSelectValues([v.toString()])
              }}
              options={contextualFooterOptions}
              placement="top"
              value={selectValues}
            />
            <Button
              id="continue-btn"
              appearance="primary"
              onClick={() => handleBtnAction(selectValues[0])}
              data-testid="confirm-batch-button"
            >
              {t(getl10n('contextualFooterContinue'))}
            </Button>
          </FooterLeadingArea>
        </FooterContent>
      </ContextualToolbar>
    )
  }

  return (
    <ContextualToolbar
      boxShadow
      data-testid={dataTestId}
      variant="select"
      show={numSelectedItems > 0}
      leadingArea={{
        children: (
          <ContextualMenu
            anchorNode={
              <Button appearance="ghost" data-testid="select-button" id="select-btn">
                {t(getl10n('actionTitle'))}
              </Button>
            }
            data-testid="contextual-footer-select-menu"
            placement="top-end"
            onClick={(_, option) => handleBtnAction(option.value)}
          >
            <MenuList>{items}</MenuList>
          </ContextualMenu>
        )
      }}
      trailingArea={{
        button: {
          appearance: 'secondary',
          'data-testid': 'cancel-button',
          id: 'cancelBtn',
          label: t(getl10n('contextualFooterCancel')),
          onClick: onCancel
        },
        selectedText: {
          'data-testid': 'contextual-footer-items-label',
          label:
            numSelectedItems === 1
              ? t(getl10n('contextualFooterItemSelect'), { number: 1 })
              : t(getl10n('contextualFooterItemsSelect'), { number: numSelectedItems })
        }
      }}
    />
  )
}

export default UserContextualFooter
