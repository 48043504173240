import styled from 'styled-components'
import primitives from '@veneer/primitives'

import { Search as VeneerSearch } from '@veneer/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const AddUser = styled.div`
  vertical-align: bottom;
  margin-bottom: 30px;
`

export const Content = styled.div`
  justify-content: space-between;
`

export const LoaderContent = styled.div`
  flex-basis: 100%;
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-grow: 1;
`

export const Search = styled(VeneerSearch)`
  max-width: 312px;
  width: 100%;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    max-width: 100%;
  }
`

export const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      & > button:first-of-type {
        width: 100%;
      }
    }
  }
`

export const TitleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
`

export const ReloadArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ActionAreaFilterTags = styled.div`
  display: flex;
  width: 100%;
`
export const FormContainer = styled.div`
  display: flex;
  width: 100%;
`
