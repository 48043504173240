import { SetStateAction } from 'react'
import { UserData } from '../interfaces/manageUsersInterface'
import { EN_US, StatusValues } from './constants'
import { PermissionSet, getPermissionSetById } from './permissionSet'

export const capitalizeString = (word: string): string => {
  word = word.toLowerCase()
  return word[0].toUpperCase() + word.slice(1)
}

export const getDateAndTime = () => {
  const today = new Date()
  const locale = 'en'
  const date = `
  ${today.toLocaleDateString(locale, { month: 'short' })} ${today.getDate()},
  ${today.toLocaleDateString(locale, { year: 'numeric' })}`
  const time = today.toLocaleTimeString(locale, {
    hour: '2-digit',
    hour12: true,
    minute: '2-digit',
    second: '2-digit'
  })
  return {
    date,
    time
  }
}

export const sort = <Type>(data: Array<Type>, orderBy: string, orderType: string) => {
  return data.sort((obj1, obj2) => {
    const value =
      orderType === 'descending'
        ? descendingComparator(obj1, obj2, orderBy)
        : -descendingComparator(obj1, obj2, orderBy)

    return value
  })
}

export const descendingComparator = <Type>(obj1: Type, obj2: Type, orderBy: string) => {
  const obj1Sort = obj1[orderBy] && obj1[orderBy].toString().toUpperCase()
  const obj2Sort = obj2[orderBy] && obj2[orderBy].toString().toUpperCase()
  if (obj2Sort < obj1Sort) {
    return -1
  }
  return obj2Sort > obj1Sort ? 1 : 0
}

export const deSelectRow = (dataArray: UserData[], checked: boolean) => {
  const updatedDataArray = [...dataArray]
  updatedDataArray.map((row) => {
    row.rowConfig.selected = checked
  })
  return updatedDataArray
}

export const selectRow = (dataArray: UserData[], checked: boolean, id?: number) => {
  const updatedDataArray = [...dataArray]
  const index = updatedDataArray.findIndex((row) => row.uid === id)
  updatedDataArray[index].rowConfig.selected = checked
  return updatedDataArray
}

export const handleMultipleUsers = (dataArray: UserData[], checked: boolean, isIndeterminate: boolean) => {
  return dataArray.map((row) => {
    const newRow = row
    if (!newRow.rowConfig.disabled) {
      newRow.rowConfig.selected = !isIndeterminate
      if (newRow.rowConfig.selected === true) {
        newRow.rowConfig.selected = checked
      }
    }
    return newRow
  })
}

export const selectionState = (dataArray: UserData[]) => {
  const total = dataArray.length
  const selected = dataArray.filter((row) => row.rowConfig.selected).length

  switch (selected) {
    case 0:
      return 'none'
    case total:
      return 'all'
    default:
      return 'indeterminated'
  }
}

export const filter = (
  userListData: Array<UserData>,
  permissionSet: PermissionSet,
  searchItem: string,
  searchItems: string[]
) => {
  const permSetValues = permissionSet?.map((item) => item?.label?.toLowerCase())
  if (searchItem.length === 0 && searchItems.length === 0) {
    return userListData
  }
  const filterdata = userListData
    .filter((user) => {
      if (searchItem.length != 0) {
        return (
          user?.fullName?.toLowerCase().includes(searchItem.toLowerCase()) ||
          user?.emailLabel?.toLowerCase().includes(searchItem.toLowerCase()) ||
          user?.roleLabel?.toLowerCase().includes(searchItem.toLowerCase()) ||
          user?.state?.toLowerCase().includes(searchItem.toLowerCase())
        )
      } else {
        return user
      }
    })
    .filter((user) => {
      if (searchItems.length != 0) {
        if (searchItems.some((item) => permSetValues.includes(item))) {
          if (searchItems.some((item) => StatusValues.includes(item))) {
            return (
              searchItems.some((value) => user.state.toLowerCase().includes(value)) &&
              searchItems.some((value) => user.roleLabel.toLowerCase() === value.toLowerCase())
            )
          } else {
            return searchItems.some((value) => user.roleLabel.toLowerCase() === value.toLowerCase())
          }
        } else {
          return (
            searchItems.some((value) => user.state.toLowerCase().includes(value)) ||
            searchItems.some((value) => user.roleLabel.toLowerCase() === value.toLowerCase())
          )
        }
      } else {
        return user
      }
    })
  return filterdata
}

export const getUniqueInviteEmails = (usersToInvite) => {
  return usersToInvite.filter(
    (item, index, self) => index === self.findIndex((t) => t.email?.toLowerCase() === item.email?.toLowerCase())
  )
}

export const mergeData = (parentdata, childdata) => {
  return parentdata.map(
    (parentitem) =>
      childdata.find((item) => item.resourceId === parentitem.resourceId && item.rowConfig.selected) || parentitem
  )
}

export const addDataTestID = () => {
  const container = document.querySelectorAll('[data-testid=manage-users]')[0]

  if (!container) {
    return
  }

  const input = container.querySelectorAll('input')[1]
  const tabList = container.querySelectorAll('.tab-list')[0]

  if (input) {
    input.setAttribute('data-testid', 'select-all-checkbox')
  }

  if (tabList) {
    tabList.querySelectorAll('span')[0].setAttribute('data-testid', 'all-users-tab-span')
  }
}

export const addDataTestIdToClearButton = () => {
  const container = document.getElementsByClassName('container')[0]
  const trailingButton = container.getElementsByClassName('vn-trailing_button')[0]
  if (trailingButton) trailingButton.setAttribute('data-testid', 'search-clear-button')
}

export const addDataTestIdToTableRows = (userLen: number) => {
  const container = document.getElementsByClassName('users-data-table')[0]
  const table = container?.getElementsByTagName('tbody')?.[0]
  if (table) {
    for (let i = 0; i < userLen - 1; i++) {
      table?.getElementsByTagName('tr')?.[i]?.setAttribute('data-testid', 'user-checkbox-' + i)
    }
  }
}
export const addAllUsersDataTestID = (userLen: number) => {
  const container = document.getElementsByClassName('container')[0]
  const table = container?.getElementsByTagName('table')[0]
  if (table) {
    for (let i = 0; i <= userLen; i++) {
      table.getElementsByClassName('vn-checkbox__span')[i].setAttribute('data-testid', 'table-entry-checkbox-' + i)
    }
  }
}

export const addDataTestIDtoInviteUserTable = () => {
  const table = document.querySelectorAll('[data-testid=invite-user-table]')[0]
  const body = table.getElementsByTagName('tbody')[0]
  const row = Array.from(body.getElementsByTagName('tr'))

  if (row.length != 0) {
    for (let i = 0; i < row.length; i++) {
      row[i]?.getElementsByTagName('td')[0]?.setAttribute('data-testid', 'invite-table-email-id-' + (i + 1))
      row[i]?.getElementsByTagName('td')[1]?.setAttribute('data-testid', 'invite-table-role-' + (i + 1))
    }
  }
}

export const addDataTestIDtoFailureNotification = () => {
  const divs = Array.from(document.getElementsByTagName('div'))
  for (let i = 0; i < divs.length; i++) {
    if (divs[i].getAttribute('role') === 'alert') {
      divs[i].getElementsByTagName('div')[1].setAttribute('data-testid', 'failure-toast-retry-button')
      divs[i].getElementsByTagName('button')[0].setAttribute('data-testid', 'failure-toast-close-button')
    }
  }
}

export const addDataTestIdToPagination = (currentPage, totalItems) => {
  const container = document.querySelectorAll('[data-testid=manage-users]')[0]
  if (container) {
    const pagesize = container.getElementsByTagName('ul')[0]
    container.getElementsByClassName('next')[0].setAttribute('data-testid', 'next-page-button')
    container.getElementsByClassName('previous')[0].setAttribute('data-testid', 'previous-page-button')
    container.getElementsByTagName('button')[8].setAttribute('data-testid', 'select-page-size')

    pagesize.parentElement.setAttribute('id', 'pagination-dropdown')
    pagesize.parentElement.parentElement.setAttribute('id', 'pagination-dropdown-height')

    const listItems = Array.from(pagesize.getElementsByTagName('li'))
    for (const [i, item] of listItems.entries()) {
      item.setAttribute('data-testid', 'page-size-option-' + (i + 1))
    }

    const pageNumbers = Array.from(container.getElementsByClassName('page'))
    pageNumbers.forEach((item) => {
      if (item.textContent == currentPage) {
        item.setAttribute('data-testid', 'current-page')
      } else {
        item.removeAttribute('data-testid')
      }
    })
    const spanText = Array.from(document.getElementsByTagName('span'))
    spanText.forEach((item) => {
      const trial = 'of ' + String(totalItems)
      if (item && item.textContent === trial) {
        item.setAttribute('data-testid', 'total-items')
      }
    })
  }
}

export const disableTitleOnContextualSvgIcon = () => {
  const contextualFooterIcon = document.getElementsByClassName('vn-select__open-button')[0]
  if (contextualFooterIcon) {
    contextualFooterIcon.removeAttribute('title')
  }
}

export const disableTitleOnSvgIcon = () => {
  const trailingBtn = document.getElementsByClassName('vn-trailing_button')[0]
  if (trailingBtn) {
    trailingBtn.removeAttribute('title')
  }
  const getSvg = Array.from(document.getElementsByTagName('svg'))
  getSvg.forEach((item) => {
    const title = item.getElementsByTagName('title')[0]
    title ? title.remove() : ''
  })
}

export const addDataTestIdToNoItems = () => {
  const errorText = Array.from(document.getElementsByTagName('span'))
  errorText.forEach((item) => {
    if (item && item.textContent === 'No Items Found') {
      item.setAttribute('data-testid', 'no-items-warning-message')
      const table = document.querySelectorAll('[data-testid="users-data-table"]')[0]
      const tbody = table.getElementsByTagName('tbody')[0]
      tbody.getElementsByTagName('svg')[0].setAttribute('data-testid', 'no-items-warning-icon')
      tbody.getElementsByTagName('div')[0].setAttribute('data-testid', 'no-items-warning-section')
    }
  })
}

export const changeTextAreaBorderOnError = () => {
  const textArea = document.getElementById('text-area-uncontrolled')
  const textareaError = textArea.getAttribute('aria-invalid')
  textareaError === 'true'
    ? textArea.parentElement.setAttribute('style', 'border-color:#be1313')
    : textArea.parentElement.removeAttribute('style')
}

export const isShowRoleMessage = (displayAdminRoleWarning, permissionSet, rolePermId) => {
  const ps = getPermissionSetById(permissionSet, rolePermId)
  return displayAdminRoleWarning && ps?.type === 'admin'
}
export const getUserFullName = (givenName, familyName) => {
  const hasName = givenName && familyName
  if (hasName) {
    return `${givenName} ${familyName}`
  } else {
    return givenName || familyName || '-'
  }
}

export const createTag = (tagKey, active, pending, expired, STATUS_LABEL_KEY, ROLE_LABEL_KEY, locale) => {
  let tag = ''
  const isUsLocale = locale === EN_US
  const isStatusKey = () => {
    return tagKey.toLowerCase() === active || tagKey.toLowerCase() === pending || tagKey.toLowerCase() === expired
  }
  const capitalizeTagKey = (tagKey: string) => {
    return tagKey.charAt(0).toUpperCase() + tagKey.slice(1)
  }
  if (isStatusKey() && isUsLocale) {
    tag = `${STATUS_LABEL_KEY}: ${capitalizeTagKey(tagKey)}`
  } else if (isStatusKey() && !isUsLocale) {
    tag = `${STATUS_LABEL_KEY}: ${tagKey}`
  } else if (!isStatusKey() && isUsLocale) {
    tag = `${ROLE_LABEL_KEY}: ${capitalizeTagKey(tagKey)}`
  } else {
    tag = `${ROLE_LABEL_KEY}: ${tagKey}`
  }
  return tag
}

export const handleResetToDefaultState = (
  defaultOrder: string[],
  order: string[],
  setDisableResetToDefault: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }
) => {
  defaultOrder = defaultOrder.filter((item) => item !== 'uid')
  order = order.filter((item) => item !== 'uid')
  if (JSON.stringify(defaultOrder) !== JSON.stringify(order)) {
    setDisableResetToDefault(false)
  } else {
    setDisableResetToDefault(true)
  }
  return
}
