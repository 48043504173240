import React, { useState } from 'react'
import GenericModal from '../../shared-components/Modals/GenericModal'
import { FilePicker } from '@veneer/core'
import { CSVLink } from 'react-csv'
import Papa from 'papaparse'
import { headers, sample } from '../../utils/constants'
import useRootContext from '../../contexts/Root/useRootContext'
import { getl10n } from '../../utils/localizationMap'

const ImportUsersModal = ({ importedUsers, modalTitle, onClose, showModal }) => {
  const csvLink = {
    data: sample,
    filename: 'SampleCSV.csv',
    headers: headers
  }
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const [values, setValues] = useState([])
  const [importEnable, setImportEnable] = useState<boolean>(true)
  const handleOnChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setValues(results.data)
        setImportEnable(false)
      }
    })
  }

  return (
    <GenericModal
      dataTestId="import-users-modal"
      id="import-users-modal"
      show={showModal}
      header={{ title: modalTitle }}
      footer={{
        buttons: {
          cancel: {
            dataTestId: 'import-users-modal-cancel-btn',
            label: `${t(getl10n('userSettingsModalLeave'))}`,
            onClick: () => {
              onClose()
              setImportEnable(true)
            }
          },
          proceed: {
            dataTestId: 'import-users-modal-import-button',
            disabled: importEnable,
            label: `${t(getl10n('importLabel'))}`,
            onClick: (e) => {
              e.preventDefault()
              importedUsers(values)
            }
          }
        }
      }}
    >
      <div className="import-users-modal-content" data-testid="import-users-modal-content">
        <div className="body import-users-modal-desc">{t(getl10n('uploadLabel'))}</div>

        <div className="label import-users-modal-label-fp">{t(getl10n('browseLabel'))}</div>

        <FilePicker label={t(getl10n('selectFile'))} onChange={handleOnChange} content="" nonce="" rel="" rev="" />

        <div className="label import-users-link">
          <CSVLink className="import-users-link" {...csvLink}>
            {t(getl10n('downloadFile'))}
          </CSVLink>
        </div>
      </div>
    </GenericModal>
  )
}

export default ImportUsersModal
