import React, { createContext } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import ShellV1Type from '../../types/shell/v1'
import ShellV2Type from '../../types/shell/v2'

export type RootContext = {
  children?: React.ReactNode
  shell: ShellV1Type & ShellV2Type
  stack: Stack
  country: string
}

const RootContext = createContext({} as RootContext)

export default RootContext
