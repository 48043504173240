import React from 'react'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import GenericModal from '../Modals/GenericModal'
import useRootContext from '../../contexts/Root/useRootContext'
import { getl10n } from '../../utils/localizationMap'

type NavigationBlockerModalProps = {
  showModal: boolean
  onConfirm: () => void
  onClose: () => void
  modalTitle: string
  usersToInviteLength: number
  customModalWidth?: string
  customModalDescWidth?: string
}

const NavigationBlockerModal = ({
  showModal,
  onConfirm,
  onClose,
  modalTitle,
  usersToInviteLength,
  customModalWidth,
  customModalDescWidth
}: NavigationBlockerModalProps) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  return (
    <GenericModal
      dataTestId="navigation-blocker-modal"
      header={{
        dataTestId: 'navigation-blocker-modal-title',
        leadingIcon: <IconWarningAlt color="darkOrange6" filled size={36} />,
        title: modalTitle
      }}
      id="navigation-blocker-modal"
      footer={{
        buttons: {
          cancel: {
            dataTestId: 'navigation-blocker-modal-no-btn',
            label: usersToInviteLength ? t(getl10n('modalUnsavedChangesCancel')) : t(getl10n('noLabel')),
            onClick: onClose
          },
          proceed: {
            dataTestId: 'navigation-blocker-modal-yes-button',
            label: usersToInviteLength ? t(getl10n('modalUnsavedChangesLeave')) : t(getl10n('yesLabel')),
            onClick: onConfirm
          }
        }
      }}
      show={showModal}
      customModalWidth={customModalWidth}
      customModalDescWidth={customModalDescWidth}
    >
      <div className="body-large" data-testid="navigation-blocker-modal-description">
        {t(getl10n('userSettingsModalContent'))}
      </div>
    </GenericModal>
  )
}

export default NavigationBlockerModal
