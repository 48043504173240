import React from 'react'
import Button, { ButtonInterface } from '@veneer/core/dist/scripts/button'
import { StyledModal, ButtonGroup } from './styles'
import Header from './Header'

type ButtonProps = {
  label: string
  dataTestId?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
} & ButtonInterface

type GenericModalProps = {
  children?: React.ReactNode
  id: string
  onClose?: () => void
  show?: boolean
  dataTestId?: string
  header: {
    dataTestId?: string
    title: string
    leadingIcon?: React.ReactNode
  }
  footer: {
    buttons: {
      proceed: ButtonProps
      cancel: ButtonProps
    }
  }
  customModalWidth?: string
  customModalDescWidth?: string
}

const GenericModal = ({
  children,
  id,
  onClose,
  show,
  dataTestId,
  header,
  footer,
  customModalWidth,
  customModalDescWidth
}: GenericModalProps) => {
  const { dataTestId: proceedButtonTestId, label: proceedButtonLabel, ...proceedButtonProps } = footer.buttons.proceed
  const { dataTestId: cancelButtonTestId, label: cancelButtonLabel, ...cancelButtonProps } = footer.buttons.cancel

  return (
    <StyledModal
      id={id}
      closeOnBlur={false}
      data-testid={dataTestId}
      footer={
        <ButtonGroup>
          <Button appearance="secondary" data-testid={cancelButtonTestId} {...cancelButtonProps}>
            {cancelButtonLabel}
          </Button>
          <Button data-testid={proceedButtonTestId} {...proceedButtonProps}>
            {proceedButtonLabel}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
      show={show}
      customModalWidth={customModalWidth}
      customModalDescWidth={customModalDescWidth}
    >
      <Header dataTestId={header.dataTestId} leadingIcon={header.leadingIcon} title={header.title} />
      {children}
    </StyledModal>
  )
}

export default GenericModal
