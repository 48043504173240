import React, { useState, useCallback, useEffect, useMemo } from 'react'
import Select from '@veneer/core/dist/scripts/select'
import GenericModal from '../GenericModal'
import { Description } from './styles'
import { RoleWarningInfo } from '../../RoleWarningInfo'
import useRootContext from '../../../contexts/Root/useRootContext'
import { getl10n } from '../../../utils/localizationMap'
import usePreferencesContext from '../../../contexts/Preferences/usePreferencesContext'
import { getSelectablePermissionSet } from '../../../utils/permissionSet'

const AssignRoleModal = ({
  permissionSet,
  isLoading,
  modalTitle,
  onClose,
  onConfirm,
  selectedUserRole,
  show,
  rolesDataSource
}) => {
  const { shell } = useRootContext()
  const { displayAdminRoleWarning, manageUsers } = usePreferencesContext()
  const { assignRoleModal } = manageUsers
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const selectablePermissionSet = useMemo(() => getSelectablePermissionSet(permissionSet), [permissionSet])
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true)
  const [selectedItem, setSelectedItem] = useState<string>('')
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const handleRoleChange = ({ value: rolePermId }) => {
    setDisableConfirm(false)
    setShowWarning(
      displayAdminRoleWarning &&
        !!selectablePermissionSet.find((ps) => ps.rolePermId === rolePermId && ps.type === 'admin')
    )
    setSelectedItem(rolePermId)
  }

  useEffect(() => {
    if (!show) {
      setSelectedItem('')
      setDisableConfirm(true)
    }
  }, [show])

  const getOptions = useCallback(() => {
    let getSortedData
    if (rolesDataSource !== 'api') {
      getSortedData = getSelectablePermissionSet(permissionSet)
    } else {
      getSortedData = [...permissionSet].sort((a, b) => a.label.localeCompare(b.label))
    }
    return getSortedData.map((item) => {
      return {
        disabled:
          item.name === (selectedUserRole && getSortedData.length > 1) ||
          selectedUserRole?.props?.children[1] === item.label,
        label: item.label,
        value: item.rolePermId
      }
    })
  }, [rolesDataSource, selectedUserRole, permissionSet])

  return (
    <GenericModal
      dataTestId="assign-role-modal"
      id="assign-role-modal"
      onClose={onClose}
      show={show}
      header={{
        dataTestId: 'assign-role-modal-title',
        title: modalTitle
      }}
      footer={{
        buttons: {
          cancel: {
            label: t(getl10n('modalCancel')),
            onClick: onClose,
            disabled: isLoading,
            dataTestId: 'assign-role-cancel-btn'
          },
          proceed: {
            disabled: disableConfirm,
            label: t(getl10n('assignRoleConfirm')),
            onClick: () => onConfirm(selectedItem),
            loading: isLoading,
            dataTestId: 'assign-role-confirm-btn'
          }
        }
      }}
    >
      <Description>{t(getl10n('assignRoleDescription'))}</Description>
      <Select
        separateLabel={assignRoleModal.select.displaySeparateLabel}
        placeholder={t(getl10n('placeHolderSelect'))}
        clearIcon={false}
        data-testid="assign-role-select-box"
        id="select-role"
        label={t(getl10n('assignRoleSelect'))}
        onChange={handleRoleChange}
        onClear={() => setSelectedItem('')}
        options={getOptions()}
        value={[selectedItem]}
      />
      {showWarning && <RoleWarningInfo />}
    </GenericModal>
  )
}

export default AssignRoleModal
