import React from 'react'
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info'
import { Container, Content, Description } from './styles'
import Button from '@veneer/core/dist/scripts/button'
import IconX from '@veneer/core/dist/scripts/icons/icon_x'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'

export const InlineNotification = ({
  type = 'informative',
  title,
  description,
  onClose = () => {
    console.log('InlineNotification onClose not implemented')
  },
  closeButton = true,
  'data-testid': dataTestId
}) => {
  let icon
  switch (type) {
    case 'warning':
      icon = <IconWarningAlt filled color="darkOrange6" size={24} />
      break
    case 'informative':
    default:
      icon = <IconInfo filled color="gray5" size={24} />
      break
  }

  const descriptionDataTestId = dataTestId ? `${dataTestId}-description` : ''

  return (
    <Container className={type}>
      {icon}
      <Content>
        <p className="label" data-testid={descriptionDataTestId}>
          {title}
        </p>
        <Description className="caption-small">{description}</Description>
      </Content>
      {closeButton && <Button appearance="ghost" leadingIcon={<IconX />} onClick={onClose} small />}
    </Container>
  )
}

export default InlineNotification
