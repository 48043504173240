import React, { useState, useCallback } from 'react'
import { Card, Select, IconInfo } from '@veneer/core'
import { InviteUser } from '../../../interfaces/inviteUsersInterface'
import { changeTextAreaBorderOnError } from '../../../utils/commonMethods'
import { defaultFormState, formatField, formValidations } from '../../../utils/formValidationHelper'
import { RoleWarningInfo } from '../../../shared-components/RoleWarningInfo'
import useForm from '../../../utils/useForm'
import usePreferencesContext from '../../../contexts/Preferences/usePreferencesContext'
import useRootContext from '../../../contexts/Root/useRootContext'
import {
  EnterEmailAddressTextChanged,
  InviteUsersAddButtonClicked,
  publishEvent,
  SelectRoleDropDownButtonChanged
} from '../../../utils/analytics'
import { getl10n } from '../../../utils/localizationMap'
import { PermissionSet, getPermissionSetById, getSelectablePermissionSet } from '../../../utils/permissionSet'
import { isShowRoleMessage } from '../../../utils/commonMethods'
import { AddButton, Content, SubInfo, TextArea } from './styles'
import { ActiveUser } from '../../../interfaces/manageUsersInterface'

type AddUserCardProps = {
  disableTableActions: boolean
  onAddUsers: (users: InviteUser[]) => void
  permissionSet: PermissionSet
  activeUser: ActiveUser
  rolesDataSource: string
}

const AddUserCard = ({ disableTableActions, onAddUsers, permissionSet, rolesDataSource }: AddUserCardProps) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  const { inviteUsers: inviteUsersPreferences, displayAdminRoleWarning } = usePreferencesContext()
  const { addUsers: addUsersPreferences } = inviteUsersPreferences || {}
  const { displayInviteIntroText, displayRoleSubInfo, displayEmailSubInfo, displaySmallAddButton } =
    addUsersPreferences || {}

  const { formState, setFormState, handleChange } = useForm(defaultFormState(permissionSet), formValidations)
  const [formError, setformError] = useState<boolean>(false)
  const [showRoleMessage, setShowRoleMessage] = useState<boolean>(false)

  const getEmails = (inviteUserInput: string) => {
    return inviteUserInput.split(/(.+?)(?:,\s*|$)/).filter((a: string) => !!a)
  }

  const onAdd = () => {
    setFormState({ ...formState, isSaving: true })
    const emailsToInvite = getEmails(formState.userEmails.value)
    const usersToInvite: InviteUser[] = emailsToInvite.map((email: string) => {
      return {
        email,
        rolePermId: formState.rolePermId.value
      }
    })
    if (!formState.hasErrors) {
      onAddUsers(usersToInvite)
      setFormState(defaultFormState(permissionSet))
      setShowRoleMessage(false)
      publishEvent(EnterEmailAddressTextChanged(emailsToInvite.length > 0))
      publishEvent(InviteUsersAddButtonClicked)
    } else {
      setformError(true)
      setTimeout(() => {
        setformError(false)
      }, 1000)
    }
  }

  const getOptions = () => {
    let getSortedData
    if (rolesDataSource !== 'api') {
      getSortedData = getSelectablePermissionSet(permissionSet)
    } else {
      getSortedData = [...permissionSet].sort((a, b) => a.label.localeCompare(b.label))
    }
    return getSortedData.map((ps) => ({
      label: ps.label,
      value: ps.rolePermId
    }))
  }
  const handleRoleSelection = ({ value: rolePermId }) => {
    const ps = getPermissionSetById(permissionSet, rolePermId)
    setShowRoleMessage(isShowRoleMessage(displayAdminRoleWarning, permissionSet, rolePermId))
    publishEvent(SelectRoleDropDownButtonChanged(ps?.label))
    handleChange('rolePermId', rolePermId)
  }

  const getSelectProps = useCallback(() => {
    const { displayPlaceholder, displaySeparateLabel } = addUsersPreferences?.roleSelect || {}

    const placeholder = displayPlaceholder ? `${t(getl10n('selectRole'))}` : undefined
    const separateLabel = displaySeparateLabel

    return {
      placeholder,
      separateLabel
    }
  }, [addUsersPreferences, t])

  const getTextAreaProps = useCallback(() => {
    const { displaySeparateLabel } = addUsersPreferences?.emailTextArea || {}

    const separateLabel = displaySeparateLabel
    const label = separateLabel ? `${t(getl10n('addUserTextAreaLabel'))}` : undefined

    return {
      separateLabel,
      label
    }
  }, [addUsersPreferences, t])

  return (
    <Card
      data-testid="add-user-card"
      appearance="dropShadow"
      content={
        <Content>
          {displayInviteIntroText && (
            <div className="body" data-testid="intro-text">
              {t(getl10n('addUserText'))}
            </div>
          )}

          <div>
            <Select
              clearIcon={false}
              data-testid="add-user-select-box"
              disabled={disableTableActions}
              id="select-usage"
              label={t(getl10n('inviteRole'))}
              onChange={handleRoleSelection}
              options={getOptions()}
              value={[formState.rolePermId.value]}
              required
              {...getSelectProps()}
            />

            {displayEmailSubInfo && (
              <SubInfo>
                <IconInfo filled size={20} />
                <span className="caption">{t(getl10n('noInvitationLabel'))}</span>
              </SubInfo>
            )}
          </div>

          {showRoleMessage && <RoleWarningInfo />}

          <div>
            <TextArea
              shake={formError}
              data-testid="user-emails-text-area"
              disabled={disableTableActions}
              error={Boolean(formState.userEmails.error)}
              height="200px"
              helperText={t(formatField(formState.userEmails))}
              id="text-area-uncontrolled"
              label={t(getl10n('addUserTextAreaLabel'))}
              name="textAreaComp"
              onChange={(value) => {
                handleChange('userEmails', value)
                setTimeout(() => {
                  changeTextAreaBorderOnError()
                }, 100)
              }}
              value={formState.userEmails.value}
              required
              {...getTextAreaProps()}
            />

            {displayRoleSubInfo && (
              <SubInfo>
                <IconInfo filled size={20} />
                <span>
                  {t(getl10n('emailInviteLabel'))}
                  <br></br>
                  {t(getl10n('duplicateEmailLabel'))}
                </span>
              </SubInfo>
            )}
          </div>

          <AddButton
            data-testid="add-button"
            appearance="primary"
            small={displaySmallAddButton}
            onClick={onAdd}
            disabled={!formState.hasChanges || !formState.userEmails.value || !formState.rolePermId.value}
          >
            {t(getl10n('addLabel'))}
          </AddButton>
        </Content>
      }
    />
  )
}

export default AddUserCard
