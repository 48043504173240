import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs, NavigationArea } from './styles'

const HeaderBreadcrumbs = ({ breadcrumbsItems }) => (
  <NavigationArea data-testid="user-navigation-area">
    <Breadcrumbs data-testid="user-breadcrumb" items={breadcrumbsItems} />
  </NavigationArea>
)

HeaderBreadcrumbs.defaultProps = {
  breadcrumbsItems: []
}

HeaderBreadcrumbs.propTypes = {
  breadcrumbsItems: PropTypes.arrayOf(PropTypes.any)
}

export default HeaderBreadcrumbs
