import styled from 'styled-components'
import primitives from '@veneer/primitives'
import Select from '@veneer/core/dist/scripts/select'
import VeneerTable from '@veneer/core/dist/scripts/table'
import { mixins } from '@jarvis/react-portal-addons'

export const Table = styled(VeneerTable)`
  & > div {
    :first-child {
      height: auto;
      flex-wrap: nowrap;

      @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
        flex-flow: column;
        & > div:nth-child(1) {
          height: auto;
          flex-wrap: nowrap;
          margin-bottom: 16px;
          width: 100%;
          margin-left: 0;
        }

        & > div:nth-child(2) {
          margin-left: 0;
        }
      }
    }

    & > button {
      height: fit-content;
    }
  }
`

export const ActionSelect = styled(Select)`
  && {
    min-width: 224px;
    background-color: white;
    border-radius: 12px;

    @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
      min-width: initial;
      width: 100%;
    }
  }
`

export const UserName = styled.span`
  max-width: 174px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

export const UserStatus = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
`

// See SMBP-1341:
// Workaround to adjust the ContextualMenu div in iOS.
export const ColumnPiece = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 36px;
  align-items: center;
  white-space: nowrap;
`

export const ContextualMenuArea = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  :hover {
    z-index: 1;
  }
`

export const UserInfo = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`

// See SMBP-1268:
// Workaround to adjust the alignment of the reorder column modal.
// This was suggested by the Veneer team.
//
// In the future Veener should expose a property for us to be able to
// customize this modal; then this style can be removed.
export const ReorderColumnPortal = styled.div`
  .vn-modal--footer {
    justify-content: right;

    div {
      flex-direction: row-reverse;
      gap: 12px;

      @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
        gap: 0;

        button:not(:last-child) {
          margin-bottom: 0;
          margin-left: 12px;
        }
      }
    }
  }
  .vn-modal--content {
    & > * span {
      word-break: normal;
    }
  }
`

export const FooterLeadingArea = styled.div`
  display: flex;
  gap: ${primitives.layout.size4}px;
  align-items: center;
`

export const FooterTrailingArea = styled.div`
  display: flex;
  gap: ${primitives.layout.size3}px;
  align-items: center;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;

  ${mixins.xs(`
    flex-direction: column-reverse;
    align-items: center;
    gap: ${primitives.layout.size3}px;

    & > * {
      flex-direction: column;
      width: 100%;
      align-items: initial;
    }
  `)}
`
