export const isColorFromTheme = (color) => {
  if (Array.isArray(color)) {
    return true
  }
  return false
}

export const getThemeColor = (color, theme) => {
  let hexCode = theme.color
  for (const field of color) {
    hexCode = hexCode[field]
  }
  return hexCode
}
