import { AccountMgtSvcClient, UserTenantMgtSvcClient, PermissionSetSvcClient } from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import { User } from '../interfaces/manageUsersInterface'
import getUsersMock from '../mock/get-users-v3.json'
import getPermissionSetsMock from '../mock/get-permission-sets.json'
import getUserTenantMock from '../mock/get-user-tenant.json'
import resendInvitationMock from '../mock/resend-invitation.json'
import userInvitationMock from '../mock/user-invitation.json'
import inviteUsersMockData from '../mock/get-invite-user.json'
import { AuthProviderType, OrgSelectorType } from '../types/shell'

const usersMock = getUsersMock

type GetActiveUserTenantProps = {
  mock?: boolean
  orgSelector: OrgSelectorType
}

export const getActiveUserTenant = async ({ mock, orgSelector }: GetActiveUserTenantProps): Promise<string> => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return 'mocked-id'
    }
  }

  return orgSelector.getCurrentCustomer() || orgSelector.getOrgTenantId()
}

type GetActiveUserProps = {
  authProvider: AuthProviderType
  stack: Stack
  mock?: boolean
}

export const getActiveUser = async ({ authProvider, stack, mock }: GetActiveUserProps): Promise<User> => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return getUserTenantMock
    }
  }

  const client = new UserTenantMgtSvcClient(stack, authProvider.createOrgedAuthProvider())
  const response = await client.getCurrentActiveUser()
  return response.data
}

type AssignExternalUserRoleProps = {
  roleResourceId: string
  userResourceId: string
  stack: Stack
  authProvider: AuthProviderType
}

export const assignExternalUserRole = async ({
  roleResourceId,
  userResourceId,
  stack,
  authProvider
}: AssignExternalUserRoleProps) => {
  const client = new UserTenantMgtSvcClient(stack, authProvider)
  const response = await client.updateExternalUserRole(userResourceId, roleResourceId)
  return response.data
}

type AssignUserTenantRoleProps = {
  roleResourceId: string
  tenantResourceId: string
  userResourceId: string
  authProvider: AuthProviderType
  stack: Stack
}

export const assignUserTenantRole = async ({
  roleResourceId,
  tenantResourceId,
  userResourceId,
  authProvider,
  stack
}: AssignUserTenantRoleProps) => {
  const client = new UserTenantMgtSvcClient(stack, authProvider)
  const response = await client.updateUserWithTenantDetails({ tenantResourceId, userResourceId, roleResourceId })
  return response.data
}

type GetPermissionSetsProps = {
  authProvider: AuthProviderType
  stack: Stack
  mock?: boolean
}

export const getPermissionSets = async ({ authProvider, stack, mock }: GetPermissionSetsProps) => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return getPermissionSetsMock.content
    }
  }

  const permClient = new PermissionSetSvcClient(stack, authProvider)
  const permSets = await permClient.getPermissionSets()
  const content = permSets.data.content
  return content
}

type ResendInvitationProps = {
  tenantResourceId: string
  userResourceId: string
  mock?: boolean
  authProvider: AuthProviderType
  stack: Stack
}

export const resendInvitation = async ({
  tenantResourceId,
  userResourceId,
  mock,
  authProvider,
  stack
}: ResendInvitationProps) => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return resendInvitationMock
    }
  }

  const client = new AccountMgtSvcClient(stack, authProvider)
  const response = await client.resendInvitation(tenantResourceId, userResourceId)
  return response.data
}

type InviteUserWithRoleProps = {
  email: string
  roleResourceId: string
  tenantResourceId: string
  mock?: boolean
  authProvider: AuthProviderType
  stack: Stack
  language: string
  country: string
}

export const inviteUserWithRole = async ({
  email,
  roleResourceId,
  tenantResourceId,
  mock,
  authProvider,
  stack,
  language,
  country
}: InviteUserWithRoleProps) => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      return userInvitationMock
    }
  }

  const client = new AccountMgtSvcClient(stack, authProvider)
  const response = await client.inviteUserWithRole(tenantResourceId, email, roleResourceId, language, country)
  return response.data
}

type InviteUserProps = {
  id: string
  email: string
  stack: Stack
  authProvider: AuthProviderType
  roleResourceId: string
  mock?: boolean
}

export const inviteUser = async ({ id, email, stack, authProvider, roleResourceId, mock }: InviteUserProps) => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      const inviteUserMock = inviteUsersMockData
      inviteUserMock.email = email
      inviteUserMock.resourceId = id
      usersMock.push(inviteUserMock)
      return inviteUserMock
    }
  }

  const client = new AccountMgtSvcClient(stack, authProvider)
  const response = await client.inviteUser(id, email, roleResourceId)
  return response.data
}

type DeleteInvitationProps = {
  id: string
  resourceId: string
  mock?: boolean
  stack: Stack
  authProvider: AuthProviderType
}

export const deleteInvitation = async ({ id, resourceId, mock, stack, authProvider }: DeleteInvitationProps) => {
  if (process.env.NODE_ENV === 'development') {
    if (mock) {
      const index = usersMock.findIndex((user) => user.resourceId === id && user.tenantResourceId === resourceId)
      return usersMock.splice(index, 1)
    }
  }

  const client = new AccountMgtSvcClient(stack, authProvider.createOrgedAuthProvider())
  const response = await client.deleteUser(id, resourceId)
  return response
}

export const getIsTechnician = async ({ stack, authProvider, permissionSet }) => {
  const userClient = new UserTenantMgtSvcClient(stack, authProvider.createOrgedAuthProvider())
  let isTechnician
  await userClient
    .getCurrentActiveUser()
    .then(({ status, data }) => {
      if (status === 200) {
        const techPermId = permissionSet.filter((permId) => permId.label == 'Technician')
        data.rolePermId === techPermId[0].rolePermId ? (isTechnician = true) : (isTechnician = false)
      } else {
        isTechnician = false
        return isTechnician
      }
    })
    .catch(() => {
      isTechnician = false
      return isTechnician
    })
  return isTechnician
}
