import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { dateHelper, useMediaQuery, mixins } from '@jarvis/react-portal-addons'
import { Button, IconReload, IconFunnel, IconDocumentPlus, Tag, Group } from '@veneer/core'
import useRootContext from '../../contexts/Root/useRootContext'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import FilterSidebar from '../../shared-components/FilterSidebar/FilterSidebar'
import Users from './Users/users'
import {
  ActionArea,
  Search,
  TitleArea,
  SearchContainer,
  ReloadArea,
  Container,
  ActionAreaFilterTags,
  FormContainer
} from './styles'
import { addDataTestID, addDataTestIdToTableRows, addAllUsersDataTestID, createTag } from '../../utils/commonMethods'
import { getl10n } from '../../utils/localizationMap'
import {
  UserScreenDisplayed,
  UserTableRefreshButtonClicked,
  UserSearchCanceledButtonClicked,
  UserSearchStartedButtonClicked,
  UserInviteButtonClicked,
  publishEvent
} from '../../utils/analytics'
import {
  ACCOUNT_MGT_USERS_WRITE_SCOPE,
  ACCOUNT_USER_MGT_WRITE_ASSIGNROLE_SCOPE,
  ACCOUNT_MGT_USERS_DELETE_SCOPE,
  SearchParamValues,
  UserStatus,
  ACTION_PUSH,
  ACTION_POP,
  ACTION_CLEARALL
} from '../../utils/constants'
import { ActiveUser, User } from '../../interfaces/manageUsersInterface'
import { PermissionSet } from '../../utils/permissionSet'
import { UserListColumnType } from '../../utils/userListColumns'
import useMoveTableColumnOptionsButtonOnResize from '../../utils/useMoveTableColumnOptionsButton'
import { CustomBreadcrumbType, SearchParamValuesType, UserStatusType } from '../App/types'

type ManageUsersProps = {
  isLoading: boolean
  setCustomBreadcrumbs?: (breadcrumbs: CustomBreadcrumbType[]) => void
  dateAndTime?: { date: string; time: string }
  hasError?: boolean
  jshellBreadcrumbs?: { users: string }
  permissionSet: PermissionSet
  retrieveUsers?: () => void
  setBreadcrumb?: (breadcrumbs: string[]) => void
  userListColumns: Array<UserListColumnType>
  users?: Array<User>
  activeUser?: ActiveUser
  useGlobalHeader?: boolean
  rolesDataSource: string
}

const ManageUsers = ({
  dateAndTime,
  hasError,
  isLoading,
  retrieveUsers,
  users,
  permissionSet,
  userListColumns,
  jshellBreadcrumbs,
  setBreadcrumb,
  setCustomBreadcrumbs,
  activeUser,
  useGlobalHeader,
  rolesDataSource
}: ManageUsersProps) => {
  const [searchItems, setSearchItems] = useState<string[]>([])
  const [searchItem, setSearchItem] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchFlag, setSearchFlag] = useState<boolean>(false)
  const [showFilterSidebar, setShowFilterSidebar] = useState<boolean>(false)
  const [writeScopeForInvite, setWriteScopeForInvite] = useState<boolean>(false)
  const [writeScopeForAssignRole, setWriteScopeForAssignRole] = useState<boolean>(false)
  const [deleteScopeForUser, setDeleteScopeForUser] = useState<boolean>(false)

  const product = window.location.search !== '' ? new URLSearchParams(window.location.search).get('status') : ''
  const { shell } = useRootContext()
  const { accessControl, localization, navigation, breadcrumbs, events } = shell
  const { t } = localization.useReactTranslatorHook()

  const { manageUsers } = usePreferencesContext()
  const { enableFilter, displayReloadTop, displayInviteButtonLeadingIcon } = manageUsers || {}
  const { formatDateNumerical, formatTime } = dateHelper

  const isMobile = useMediaQuery(mixins.mediaQueries.xs)

  useEffect(() => {
    setCustomBreadcrumbs?.([
      {
        text: t(getl10n('breadcrumbHome')),
        onClick: (e) => {
          e.preventDefault()
          if (navigation) {
            navigation.push('/')
          }
        },
        url: '/'
      },
      {
        text: t(getl10n('breadcrumbUsers'))
      }
    ])
  }, [navigation, t, setCustomBreadcrumbs])

  const handleSearchValueChange = useCallback(
    (value) => {
      setSearchValue(value)
      setSearchItem(value)
      setSearchFlag(!searchFlag)
    },
    [searchFlag]
  )

  useEffect(() => {
    addDataTestID()
    publishEvent(UserScreenDisplayed)
  }, [])

  useEffect(() => {
    if (users?.length > 0) {
      const productArray = users.filter((item) => item.state.toLowerCase() === product)
      if (productArray.length > 0) {
        addDataTestIdToTableRows(productArray.length)
        addAllUsersDataTestID(users.length)
      }
    }
  }, [hasError, product, users])

  useEffect(() => {
    if (setBreadcrumb && jshellBreadcrumbs?.users) {
      setBreadcrumb([jshellBreadcrumbs.users])
    }
  }, [jshellBreadcrumbs?.users, setBreadcrumb])

  useEffect(() => {
    events.addEventListener('ecp-banner-reload-call', retrieveUsers)
    return () => {
      events.removeEventListener('ecp-banner-reload-call', retrieveUsers)
    }
  }, [events, retrieveUsers])

  useEffect(() => {
    breadcrumbs?.remove('invite-users')
  }, [breadcrumbs])

  useEffect(() => {
    accessControl.checkScopes(ACCOUNT_MGT_USERS_WRITE_SCOPE).then((result) => setWriteScopeForInvite(result))
    accessControl
      .checkScopes(ACCOUNT_USER_MGT_WRITE_ASSIGNROLE_SCOPE)
      .then((result) => setWriteScopeForAssignRole(result))
    accessControl.checkScopes(ACCOUNT_MGT_USERS_DELETE_SCOPE).then((result) => setDeleteScopeForUser(result))
  }, [accessControl])

  const getSearchItems = (product: string, UserStatus: UserStatusType[], SearchParamValues: SearchParamValuesType) => {
    switch (product) {
      case SearchParamValues.Admin:
        return [SearchParamValues.ITAdmin]
      case SearchParamValues.EndUserSearchValue:
        return [SearchParamValues.EndUser]
      default:
        if (UserStatus.some((status) => status.value.toLowerCase() === product)) {
          return [SearchParamValues.ITAdmin, product]
        } else {
          return []
        }
    }
  }

  useEffect(() => {
    const searchItems = getSearchItems(product, UserStatus, SearchParamValues)
    setSearchItems(searchItems)
  }, [product])

  const handlePerformFilter = useCallback(
    (item: string, action: string) => {
      if (action === ACTION_PUSH) {
        setSearchItems([...searchItems, item])
      } else if (action === ACTION_POP) {
        setSearchItems(searchItems.filter((element) => element !== item))
      } else if (action === ACTION_CLEARALL) {
        setSearchItems([])
      } else {
        // do nothing
      }
    },
    [searchItems]
  )

  const reloadContent = useMemo(() => {
    const date = dateAndTime ? new Date(`${dateAndTime.date} ${dateAndTime.time}`) : null

    return (
      <ReloadArea data-testid="reload-section">
        <Button
          appearance="ghost"
          aria-label="ghost"
          data-testid="reload-button"
          leadingIcon={<IconReload />}
          onClick={() => {
            retrieveUsers()
            publishEvent(UserTableRefreshButtonClicked)
          }}
          small
          loading={isLoading}
        ></Button>

        <span className="caption" data-testid="reload-button-caption">
          {dateAndTime &&
            `${t(getl10n('lastUpdated'), {
              date: formatDateNumerical(date, localization.locale),
              time: formatTime(date, localization.locale),
              interpolation: { escapeValue: false }
            })}`}
        </span>
      </ReloadArea>
    )
  }, [isLoading, dateAndTime, formatDateNumerical, formatTime, t, localization.locale, retrieveUsers])

  const filterTagKey = useCallback(
    (tagKey: string): string => {
      const active = t(getl10n('statusActive')).toLowerCase()
      const pending = t(getl10n('statusPending')).toLowerCase()
      const expired = t(getl10n('statusExpired')).toLowerCase()

      const STATUS_LABEL_KEY = t(getl10n('statusKey'))
      const ROLE_LABEL_KEY = t(getl10n('roleKey'))

      return createTag(tagKey, active, pending, expired, STATUS_LABEL_KEY, ROLE_LABEL_KEY, localization.locale)
    },
    [localization.locale, t]
  )

  const mapFilterTags = useCallback(
    (searchItems: string[]) => {
      const numberedTagLength = searchItems.length - 3
      const filterTagsList = searchItems.map((item: string, index: number) => (
        <Tag
          data-testid="usrmgmt-filter-tags"
          id="usrmgmt-filter-tags"
          key={`filter-tag-${item}`}
          label={filterTagKey(item)}
          clearButton
          colorScheme="hpBlue"
          isTint
          onClick={() => {
            searchItems.splice(index, 1)
            retrieveUsers()
          }}
        />
      ))
      const numberedFilterTag = () => {
        return (
          <Tag
            data-testid="usrmgmt-filter-tags-numbered"
            id="usrmgmt-filter-tags-numbered"
            key="usrmgmt-filter-tags-numbered"
            label={`+${numberedTagLength}`}
            clearButton={false}
          />
        )
      }
      if (searchItems.length <= 3) {
        return filterTagsList
      } else {
        filterTagsList.splice(3, numberedTagLength, numberedFilterTag())
        return filterTagsList
      }
    },
    [filterTagKey, retrieveUsers]
  )

  const searchField = useMemo(
    () => (
      <SearchContainer data-testid="search-form">
        <Search
          data-testid="search-box"
          placeholder={t(getl10n('searchLabel'))}
          value={searchValue}
          onBlur={() => setSearchFlag(!searchFlag)}
          onChange={(value) => handleSearchValueChange(value)}
          onClear={() => {
            setSearchItem('')
            new URLSearchParams(window.location.search).delete('status')
            setSearchFlag(!searchFlag)
            publishEvent(UserSearchCanceledButtonClicked)
          }}
          onFocus={() => publishEvent(UserSearchStartedButtonClicked)}
        />

        {enableFilter && (
          <Button
            data-testid="table-filter-button"
            appearance="ghost"
            leadingIcon={<IconFunnel data-testid="icon-funnel" />}
            onClick={() => setShowFilterSidebar(true)}
            disabled={isLoading}
          >
            {t(getl10n('userFilterLabel'))}
          </Button>
        )}
      </SearchContainer>
    ),
    [enableFilter, handleSearchValueChange, isLoading, searchFlag, searchValue, t]
  )

  const userTableActionArea = useMemo(
    () => (
      <ActionArea>
        <FormContainer id="searchFormContainer">
          {!isMobile && searchField}
          {writeScopeForInvite && (
            <Button
              data-testid="invite-user-button"
              id="inviteUserButton"
              leadingIcon={displayInviteButtonLeadingIcon ? <IconDocumentPlus /> : undefined}
              onClick={() => {
                navigation.push('/users/invite')
                breadcrumbs?.add({
                  key: 'invite-users',
                  translationKey: undefined,
                  text: t(getl10n('breadcrumbInviteUsers')),
                  url: undefined
                })
                publishEvent(UserInviteButtonClicked)
              }}
            >
              {t(getl10n('breadcrumbInviteUsers'))}
            </Button>
          )}
        </FormContainer>

        {enableFilter && searchItems.length > 0 && (
          <ActionAreaFilterTags>
            <Group spacing={1} customStyle={'height: 28px'}>
              {mapFilterTags(searchItems)}
              {searchItems.length > 1 && (
                <Button
                  data-testid="clear-all-tags"
                  id="clear-all-tags"
                  appearance="ghost"
                  small
                  onClick={() => {
                    searchItems.splice(0, searchItems.length)
                    retrieveUsers()
                  }}
                >
                  {t('manageUsers.clearAll', 'Clear All')}
                </Button>
              )}
            </Group>
          </ActionAreaFilterTags>
        )}
      </ActionArea>
    ),
    [
      t,
      enableFilter,
      writeScopeForInvite,
      displayInviteButtonLeadingIcon,
      searchItems,
      mapFilterTags,
      navigation,
      breadcrumbs,
      retrieveUsers,
      searchField,
      isMobile
    ]
  )

  useMoveTableColumnOptionsButtonOnResize(isMobile, t(getl10n('columnModalTitle')))

  return (
    <Container data-testid="manage-users">
      {!useGlobalHeader && (
        <TitleArea data-testid="user-section-description">
          <p className="title-small" data-testid="users-section-title">
            {t(getl10n('pageHeader'))}
          </p>
          {displayReloadTop && reloadContent}
        </TitleArea>
      )}

      {!useGlobalHeader && (
        <p className="body" data-testid="page-description">
          {t(getl10n('pageTitle'))}
        </p>
      )}

      {!displayReloadTop && reloadContent}

      <Users
        actionArea={userTableActionArea}
        columnReorder
        controlArea={isMobile ? searchField : undefined}
        deleteScopeForUser={deleteScopeForUser}
        isLoading={isLoading}
        hasError={hasError}
        retrieveUsers={retrieveUsers}
        searchItem={searchItem}
        searchItems={searchItems}
        users={users}
        writeScopeForAssignRole={writeScopeForAssignRole}
        writeScopeForInvite={writeScopeForInvite}
        permissionSet={permissionSet}
        userListColumns={userListColumns}
        activeUser={activeUser}
        rolesDataSource={rolesDataSource}
      />

      {showFilterSidebar && (
        <div data-testid="filter-side-bar-div" id="filter-side-bar-div">
          <FilterSidebar
            data-testid="user-mngmnt-filter-side-bar"
            onclose={() => setShowFilterSidebar(false)}
            performFilter={(item: string, action: string) => handlePerformFilter(item, action)}
            appliedFilter={searchItems}
            permissionUiLabels={permissionSet}
          />
        </div>
      )}
    </Container>
  )
}

export default ManageUsers
