import { getl10n } from './localizationMap'

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
)

const testEmailPattern = (value: string) => EMAIL_REGEX.test(value)

const isPresent = (value) => !!value

export const formValidations = {
  rolePermId: (value) => !isPresent(value),
  userEmails: (value: string) => {
    const emails = value.split(/(.+?)(?:,\s*|$)/).filter((a) => !!a)
    if (!emails) {
      return true
    }
    let hasEmailError = false
    emails.map((id) => {
      if (!hasEmailError) {
        hasEmailError = !testEmailPattern(id)
      }
    })

    if (hasEmailError) {
      return getl10n('inviteOverlayEmailError')
    }
    return null
  }
}

export const formatField = (field) => {
  if (!field.value) {
    return ''
  }
  return field.error || ''
}

export const defaultFormState = (permissionSet) => {
  return {
    rolePermId: {
      value: permissionSet.length === 1 ? permissionSet[0].rolePermId : '',
      error: false
    },
    userEmails: {
      value: '',
      error: false
    },
    hasChanges: false,
    isSaving: false,
    isLoading: false
  }
}
