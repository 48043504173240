import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SideBar from '@veneer/core/dist/scripts/side_bar'
import Search from '@veneer/core/dist/scripts/search'
import Button from '@veneer/core/dist/scripts/button/button'
import Checkbox from '@veneer/core/dist/scripts/checkbox/checkbox'
import List from '@veneer/core/dist/scripts/list'
import ListItem from '@veneer/core/dist/scripts/list_item'
import { IconX } from '@veneer/core'
import useRootContext from '../../contexts/Root/useRootContext'
import { getl10n } from '../../utils/localizationMap'
import './FilterSidebar.scss'
import { ACTION_CLEARALL, ACTION_POP, ACTION_PUSH } from '../../utils/constants'

const FilterSidebar = ({ onclose, performFilter, appliedFilter, permissionUiLabels }) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [showInvalidSearchMsg, setShowInvalidSearchMsg] = useState<boolean>(false)
  const [permissionLabels, setPermissionLabels] = useState([])
  const [statusLabels, setStatusLabels] = useState([])
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const permLabelsArray = []
  const UserStatus = [
    { value: `${t(getl10n('statusActive'))}`, label: 'statusActive' },
    { value: `${t(getl10n('statusExpired'))}`, label: 'statusExpired' },
    { value: `${t(getl10n('statusPending'))}`, label: 'statusPending' }
  ]
  permissionUiLabels.map((uiLabel) => {
    if (
      !permLabelsArray.find((permLabel) => {
        if (permLabel.label === uiLabel.label) {
          permLabel.times++
          return true
        } else {
          return false
        }
      })
    ) {
      uiLabel.times = 1
      permLabelsArray.push(uiLabel)
    } else {
      // do nothing
    }
  })

  const handleSearchValue = (value: string) => {
    setSearchValue(value)
  }

  const handleClearSearch = () => {
    setSearchValue('')
    setStatusLabels(UserStatus)
    setPermissionLabels(permLabelsArray)
    setShowInvalidSearchMsg(false)
  }

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const filterdata = permLabelsArray.filter((user) => user.label.toLowerCase().includes(searchValue.toLowerCase()))
    setPermissionLabels(filterdata)
    const filterStatusdata = UserStatus.filter((user) => user.label.toLowerCase().includes(searchValue.toLowerCase()))
    setStatusLabels(filterStatusdata)
  }

  const handleFilterCheck = (e) => {
    e.target.checked ? performFilter(e.target.value, ACTION_PUSH) : performFilter(e.target.value, ACTION_POP)
  }

  useEffect(() => {
    setStatusLabels(UserStatus)
    setPermissionLabels(permLabelsArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionUiLabels])

  const handleCheckboxChange = (e: { target: EventTarget & HTMLInputElement }) => {
    handleFilterCheck(e), fillOrRemoveFromArr(e.target)
  }

  const [arr, setArr] = useState<Array<EventTarget & HTMLInputElement>>([])

  const fillOrRemoveFromArr = (target: EventTarget & HTMLInputElement) => {
    target.checked ? setArr([...arr, target]) : setArr(arr.filter((item) => item !== target))
  }

  const handleAllUncheck = useCallback((): void => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].checked = false
      setArr([])
    }
    performFilter('', ACTION_CLEARALL)
    return null
  }, [arr, performFilter])

  const mainContent = (
    <div
      data-testid="filter-sidebar-primary-div"
      id="filter-sidebar-primary-div"
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '24px'
      }}
    >
      <p
        data-testid="filter-sidebar-title"
        id="filter-sidebar-title"
        className=""
        style={{
          fontWeight: 'bold',
          fontSize: '24px',
          lineHeight: '28px',
          marginBottom: '16px'
        }}
      >
        {t(getl10n('userFilterLabel'))}
      </p>
      <p
        data-testid="filter-sidebar-description"
        id="filter-sidebar-description"
        className=""
        style={{
          fontSize: '16px',
          lineHeight: '24px',
          marginBottom: '24px'
        }}
      >
        {t(getl10n('userSelectFilterLabel'))}
      </p>
      <form data-testid="search-filters-div" id="search-filters-div" onSubmit={handleSearch}>
        <Search
          id="search-filters-box"
          className="marginR11 floatL"
          placeholder={t(getl10n('searchFilterLabel'))}
          onChange={handleSearchValue}
          onClear={handleClearSearch}
          content=""
          nonce=""
          rel=""
          rev=""
        />
      </form>
      <div data-testid="filter-block-2" id="filter-block-2" className="">
        <div data-testid="filter-block-2-label-block" id="filter-block-2-label-block" className="">
          <p data-testid="filter-block-2-label" id="filter-block-2-label" className="label">
            {t(getl10n('builtInLabel'))}
          </p>
          <span data-testid="filter-block-2-divider" id="filter-block-2-divider" className="divider">
            <hr></hr>
          </span>
        </div>
        <List data-testid="filter-block-2-list-block" id="filter-block-2-list-block" className="">
          {UserStatus &&
            statusLabels.map((statusLabel) => (
              <ListItem
                key={statusLabel.label}
                data-testid="filter-block-2-item-1"
                id="filter-block-2-item-1"
                leadingArea={
                  <Checkbox
                    data-testid="filter-block-2-checkbox-1"
                    id={statusLabel.label}
                    className="lablesCheckBox"
                    name="lablesCheckBox"
                    label={statusLabel.value}
                    value={statusLabel.value.toLowerCase()}
                    onChange={handleCheckboxChange}
                    checked={
                      appliedFilter.includes(statusLabel.value.toLowerCase())
                        ? true
                        : arr.includes(arr.filter((item) => item.id === statusLabel.label)[0])
                        ? true
                        : false
                    }
                  />
                }
              ></ListItem>
            ))}
          {permissionUiLabels &&
            permissionLabels.map((uiLabel) => (
              <ListItem
                key={uiLabel.rolePermId}
                data-testid="filter-block-2-item-4"
                id="filter-block-2-item-4"
                leadingArea={
                  <Checkbox
                    data-testid={`filter-${uiLabel.rolePermId}`}
                    id={uiLabel.rolePermId}
                    className="lablesCheckBox"
                    name="lablesCheckBox"
                    label={uiLabel?.label}
                    value={uiLabel?.label?.toLowerCase()}
                    onChange={handleCheckboxChange}
                    checked={
                      appliedFilter.includes(uiLabel?.label?.toLowerCase())
                        ? true
                        : arr.includes(arr.filter((item) => item.id === uiLabel.rolePermId)[0])
                        ? true
                        : false
                    }
                  />
                }
              ></ListItem>
            ))}
          {showInvalidSearchMsg && (
            <div data-testid="not-available-span" className="not-available-span">
              {`Searched filter not available`}
            </div>
          )}
        </List>
      </div>
      <div data-testid="bottom-margin-main-page" id="bottom-margin-main-page" className="margin-60"></div>
      {appliedFilter.length ? (
        <div
          data-testid="filter-list-contextual-footer"
          id="filter-list-contextual-footer"
          className="filterListContextualFooter"
        >
          <div
            data-testid="filter-list-contextual-footer-text"
            id="filter-list-contextual-footer-text"
            className="selectedItemsText"
          >
            {t(getl10n('contextualFooterItemsSelect'), { number: appliedFilter.length })}
          </div>
          <Button
            data-testid="filter-list-contextual-footer-clear-all-button"
            id="filter-list-contextual-footer-clear-all-button"
            className="clearAll"
            appearance="ghost"
            leadingIcon={<IconX />}
            onClick={handleAllUncheck}
          >
            {t(getl10n('clearAllLabel'))}
          </Button>
        </div>
      ) : null}
    </div>
  )

  const getContent = () => {
    return <>{mainContent}</>
  }

  return (
    <SideBar
      data-testid="filter-sidebar-container"
      id="filter-sidebar-container"
      behavior="overlay"
      position="end"
      disableBackdropClick
      size={350}
      show={true}
      content={getContent()}
      onClose={() => onclose()}
    />
  )
}

FilterSidebar.propTypes = {
  onclose: PropTypes.func.isRequired
}

export default FilterSidebar
