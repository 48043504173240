import React, { useEffect } from 'react'
import RootProvider from '../src/contexts/Root/RootProvider'
import { PreferencesContextProps } from '../src/contexts/Preferences/PreferencesContext'
import App from '../src'
import { Stack } from '@jarvis/web-stratus-client'
import resources from '../src/assets/locale'
import { setActivity } from '../src/utils/analytics'
import ShellV1Type from '../src/types/shell/v1'
import ShellV2Type from '../src/types/shell/v2'

type ShellProps = {
  v1: ShellV1Type
  v2: ShellV2Type
}

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type UserManagementProps = {
  country: string
  stack: Stack
  activity: string
  properties: PreferencesContextProps
}

const namespace = '@jarvis/react-user-management'

export default function Root({ country, activity, stack, properties }: UserManagementProps) {
  const { v1, v2 } = window.Shell as ShellProps
  const shell = {
    ...v1,
    ...v2
  }

  useEffect(() => {
    setActivity(activity || 'Users-v01')
  }, [activity])

  return (
    <section id={namespace}>
      <RootProvider
        value={{
          shell,
          stack,
          country
        }}
        resources={resources}
        preferences={properties}
      >
        <App />
      </RootProvider>
    </section>
  )
}
