import { createContext } from 'react'
import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu'
import { PopoverPlacement } from '@veneer/core/dist/scripts/shared/popover'
import { UserListColumnType } from '../../utils/userListColumns'
import { PermissionSet } from '../../utils/permissionSet'

export type InviteUserPreferences = {
  addUsers?: {
    displayInviteIntroText?: boolean
    displayRoleSubInfo?: boolean
    displayEmailSubInfo?: boolean
    displaySmallAddButton?: boolean
    displayNoEmailMessage?: boolean
    emailTextArea?: {
      displayPlaceholder?: boolean
      displaySeparateLabel?: boolean
    }
    roleSelect?: {
      displayPlaceholder?: boolean
      displaySeparateLabel?: boolean
    }
  }
  displayImportSection?: boolean
  displaySendInvitationSection?: boolean
  displayContextualInviteFooter?: boolean
  inviteList?: {
    displaySimplifiedNoEmailWarning?: boolean
  }
  unsavedChangesModal?: {
    bodyTextWidth?: string
    modalWidth?: string
  }
}

type StatusColorMapping = {
  active?: string
  expired?: string
  pending?: string
}

export type ManageUsersPreferences = {
  useGlobalHeader?: boolean
  displayInviteButtonLeadingIcon?: boolean
  displayReloadTop?: boolean
  enableFilter?: boolean
  hideContentModalRemoveUser?: boolean
  showRemoveUserModalSubcontent?: boolean
  useOrgedAuthProvider?: boolean
  displayColumnResetText?: boolean
  contextualToolbar?: {
    backgroundColor?: string
    displaySelect?: boolean
  }
  table: {
    displayFullContextualMenu?: boolean
    displayGroupColumn?: boolean
    displayNoResultsFilterText?: boolean
    displaySelectAllItemsButton?: boolean
    hideSolutionsColumn?: boolean
    pageSizeOptions?: OptionInterface<number>[]
    popoverPlacement?: PopoverPlacement
    showEmailInTooltip?: boolean
    statusColorMapping?: StatusColorMapping
    userListColumnDefaultOrder: Array<string>
    userListColumns: Array<UserListColumnType>
  }
  assignRoleModal?: {
    select?: {
      displaySeparateLabel?: boolean
    }
  }
}

export type PreferencesContextProps = {
  density?: 'high' | 'standard'
  displayBreadcrumb?: boolean
  featureFlagClientName?: string
  inviteUsers?: InviteUserPreferences
  localizationKeyMapping?: Array<string>
  manageUsers: ManageUsersPreferences
  permissionSet?: PermissionSet
  displayAdminRoleWarning: boolean
  permissionSets?: {
    dataSource: string
  }
}

export const defaultPreferences: PreferencesContextProps = {
  density: 'standard',
  displayBreadcrumb: false,
  featureFlagClientName: undefined,
  inviteUsers: {
    addUsers: {
      displayEmailSubInfo: false,
      displayInviteIntroText: false,
      displayRoleSubInfo: false,
      displaySmallAddButton: false,
      displayNoEmailMessage: true,
      emailTextArea: {
        displayPlaceholder: false,
        displaySeparateLabel: false
      },
      roleSelect: {
        displayPlaceholder: false,
        displaySeparateLabel: false
      }
    },
    displayContextualInviteFooter: false,
    displayImportSection: false,
    displaySendInvitationSection: false,
    inviteList: {
      displaySimplifiedNoEmailWarning: false
    },
    unsavedChangesModal: {
      bodyTextWidth: '327px',
      modalWidth: '612px'
    }
  },
  localizationKeyMapping: [],
  manageUsers: {
    useGlobalHeader: false,
    displayInviteButtonLeadingIcon: true,
    displayReloadTop: false,
    enableFilter: false,
    hideContentModalRemoveUser: false,
    showRemoveUserModalSubcontent: false,
    useOrgedAuthProvider: true,
    displayColumnResetText: false,
    contextualToolbar: {
      backgroundColor: '#ebf9ff',
      displaySelect: true
    },
    table: {
      displayFullContextualMenu: false,
      displayGroupColumn: false,
      displayNoResultsFilterText: true,
      displaySelectAllItemsButton: true,
      hideSolutionsColumn: false,
      pageSizeOptions: [
        {
          value: 25
        },
        {
          value: 50
        },
        {
          value: 100
        }
      ],
      popoverPlacement: 'bottom-start',
      showEmailInTooltip: false,
      statusColorMapping: {
        active: 'green7',
        pending: 'gray5',
        expired: 'red7'
      },
      userListColumnDefaultOrder: undefined,
      userListColumns: undefined
    },
    assignRoleModal: {
      select: {
        displaySeparateLabel: false
      }
    }
  },
  permissionSet: undefined,
  displayAdminRoleWarning: false
}

const PreferencesContext = createContext({} as PreferencesContextProps)

export default PreferencesContext
