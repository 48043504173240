import { useEffect, useRef } from 'react'

const useMoveTableColumnOptionsButton = (isMobile: boolean, buttonAriaLabel: string) => {
  const searchFormContainerRef = useRef<HTMLElement | null>(null)
  const columnOptionsButtonRef = useRef<HTMLElement | null>(null)
  const inviteButtonRef = useRef<HTMLElement | null>(null)
  const originalParentRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const updateRefs = () => {
      searchFormContainerRef.current = document.getElementById('searchFormContainer')
      columnOptionsButtonRef.current = document.querySelector(`[aria-label="${buttonAriaLabel}"]`)
      inviteButtonRef.current = document.getElementById('inviteUserButton')
      originalParentRef.current = columnOptionsButtonRef.current?.parentNode as HTMLElement | null
    }

    const moveButton = () => {
      if (
        !searchFormContainerRef.current ||
        !columnOptionsButtonRef.current ||
        !inviteButtonRef.current ||
        !originalParentRef.current
      ) {
        console.error('Required elements are not found in the DOM')
        return
      }

      if (isMobile) {
        if (!searchFormContainerRef.current.contains(columnOptionsButtonRef.current)) {
          searchFormContainerRef.current.insertBefore(
            columnOptionsButtonRef.current,
            inviteButtonRef.current.nextSibling
          )
        }
      } else {
        if (!originalParentRef.current.contains(columnOptionsButtonRef.current)) {
          originalParentRef.current.appendChild(columnOptionsButtonRef.current)
        }
      }
    }

    const checkAndMoveButton = () => {
      updateRefs()
      moveButton()
    }

    window.addEventListener('resize', moveButton)
    checkAndMoveButton() // Initial check

    const intervalId = setInterval(() => {
      if (document.getElementById('inviteUserButton')) {
        clearInterval(intervalId)
        checkAndMoveButton()
      }
    }, 100)

    return () => {
      window.removeEventListener('resize', moveButton)
      clearInterval(intervalId)
    }
  }, [isMobile, buttonAriaLabel])
}

export default useMoveTableColumnOptionsButton
