import styled from 'styled-components'
import VeneerBreadcrumbs from '@veneer/core/dist/scripts/breadcrumbs'
import IconChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left'
import primitives from '@veneer/primitives'
import { rgba } from 'polished'

export const BackLinkContainer = styled.nav`
  display: inline-flex;
  font-size: 14px;
  transition: background-color 100ms ease-out, color 100ms linear;
`

export const BackLinkIcon = styled(IconChevronLeft)`
  && {
    color: ${rgba(primitives.color.white, 0.05)};
  }
`

export const BackLink = styled.span`
  border-radius: 4px;
  cursor: pointer;
  padding: 4px 8px;

  &:hover {
    background-color: ${rgba(primitives.color.white, 0.05)};
  }
`

export const Breadcrumbs = styled(VeneerBreadcrumbs)``

export const NavigationArea = styled.div`
  align-items: center;
  display: flex;
  height: 42px;
  line-height: 300px;
`
