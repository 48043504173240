import { useState } from 'react'

function useForm(defaultState, formValidations) {
  const [formState, setFormState] = useState(defaultState)

  const handleChange = (fieldName, value, updateHasChanges = true) => {
    const updatedFormState = {
      ...formState,
      [fieldName]: {
        value,
        error: formValidations[fieldName](value, defaultState[fieldName].value, formState)
      }
    }

    const hasErrors = Object.values(updatedFormState).some((obj) => obj['error'])
    const hasChanges = updateHasChanges
      ? defaultState[fieldName] !== value || formState.hasChanges
      : formState.hasChanges
    setFormState({ ...updatedFormState, hasErrors, hasChanges })
  }

  return {
    formState,
    setFormState,
    handleChange
  }
}

export default useForm
