import React from 'react'
import { ModalHeader, ModalHeaderIcon } from './styles'

export type HeaderProps = {
  leadingIcon?: React.ReactNode
  title: string
  dataTestId?: string
}

const Header = ({ leadingIcon, title, dataTestId }) => (
  <ModalHeader>
    {leadingIcon && <ModalHeaderIcon>{leadingIcon}</ModalHeaderIcon>}
    <div className="title-small" data-testid={dataTestId}>
      {title}
    </div>
  </ModalHeader>
)

export default Header
