import PropTypes from 'prop-types'
import { path } from 'ramda'

const screenPath = '/ReactUserManagement/'
const eventDetailVersion = '1.1.0'

let activity = 'Users-v01'

const titleize = (s) => s?.charAt(0).toUpperCase() + s?.slice(1).toLowerCase()

export function setActivity(value) {
  activity = value
}

export const publishEvent = (eventDetail) => {
  const publishCdmEvents = path(['Shell', 'v1', 'analytics', 'publishCdmEvents'], window)

  if (!publishCdmEvents) {
    return
  }

  eventDetail.activity = activity

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType: 'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail,
      version: '1.4.0'
    }
  ])
}

export const AnalyticsEventPropType = {
  action: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  screenPath: PropTypes.string.isRequired,
  screenName: PropTypes.string.isRequired,
  screenMode: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  controlDetail: PropTypes.string.isRequired,
  version: PropTypes.string.isRequired
}

// Events

export const UserTableRefreshButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'Users',
  controlName: 'UserTableRefreshButton',
  version: eventDetailVersion
}

export const UserSearchStartedButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'Users',
  controlName: 'UserSearchButton',
  controlDetail: 'SearchStarted',
  version: eventDetailVersion
}

export const UserSearchCanceledButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'Users',
  controlName: 'UserSearchButton',
  controlDetail: 'SearchCanceled',
  version: eventDetailVersion
}

export const UserGearScreenDisplay = {
  action: 'ScreenDisplayed',
  screenPath,
  screenName: 'Users',
  controlName: 'UserGearButton',
  version: eventDetailVersion
}

export const UserGearButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'Users',
  controlName: 'UserGearButton',
  version: eventDetailVersion
}

export const UserStatusDisplayClicked = (status) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'UserGearOverlay',
    controlName: 'UserStatusDisplay',
    controlDetail: `${status ? 'Enabled' : 'Disabled'}`,
    version: eventDetailVersion
  }
}

export const UserRoleDisplayClicked = (status) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'UserGearOverlay',
    controlName: 'UserRoleDisplay',
    controlDetail: `${status ? 'Enabled' : 'Disabled'}`,
    version: eventDetailVersion
  }
}

export const UserResetToDefaultLinkClicked = {
  action: 'ControlHyperLinkClicked',
  screenPath,
  screenName: 'UserGearOverlay',
  controlName: 'UserResetToDefaultLink',
  version: eventDetailVersion
}

export const UserGearSaveButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'UserGearOverlay',
  controlName: 'UserGearSaveButton',
  version: eventDetailVersion
}

export const UserGearCancelButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'UserGearOverlay',
  controlName: 'UserGearCancelButton',
  version: eventDetailVersion
}

export const UserInviteButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'Users',
  controlName: 'UserInviteButton',
  version: eventDetailVersion
}

export const UserScreenDisplayed = {
  action: 'ScreenDisplayed',
  screenPath,
  screenName: 'Users',
  version: eventDetailVersion
}

export const UserInviteScreenDisplayed = {
  action: 'ScreenDisplayed',
  screenPath,
  screenName: 'InviteUsers',
  version: eventDetailVersion
}

export const SendInvitationButtonClicked = (role, countRole) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'InviteUsers',
    controlName: 'SendInvitationButton',
    controlDetail: `${role}: ${countRole}`,
    version: eventDetailVersion
  }
}

export const InvitationFailedDisplayed = (countFailed) => {
  return {
    action: 'ScreenDisplayed',
    screenPath,
    screenName: 'InviteUsers',
    controlName: 'InvitationFailed',
    controlDetail: `${countFailed}`,
    version: eventDetailVersion
  }
}

export const ContinueButtonClicked = (msgDetail, countDetail) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'Users',
    controlName: 'ContinueButton',
    controlDetail: `${msgDetail}: ${countDetail}`,
    version: eventDetailVersion
  }
}

// Events helpers

export const getModuleInitializedEvent = (component) => ({
  action: 'ModuleInitialized',
  screenPath,
  screenName: 'Users',
  controlName: component,
  version: eventDetailVersion
})

export const getModuleDisplayedEvent = (component, screenName) => ({
  action: 'ModuleDisplayed',
  screenPath: `${component}`,
  screenName,
  version: eventDetailVersion
})

export const SelectRoleDropDownButtonChanged = (value) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'InviteUsers',
    controlName: 'SelectRoleDropDownButton',
    controlDetail: titleize(value),
    version: eventDetailVersion
  }
}

export const EnterEmailAddressTextChanged = (provided = true) => {
  return {
    action: 'ControlButtonClicked',
    screenPath,
    screenName: 'InviteUsers',
    controlName: 'EnterEmailAddressText',
    controlDetail: provided ? 'Provided' : 'Blank',
    version: eventDetailVersion
  }
}

export const InviteUsersAddButtonClicked = {
  action: 'ControlButtonClicked',
  screenPath,
  screenName: 'InviteUsers',
  controlName: 'InviteUsersAddButton',
  version: eventDetailVersion
}
