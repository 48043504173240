import React, { useCallback, useEffect, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import IconPaperAirplane from '@veneer/core/dist/scripts/icons/icon_paper_airplane'
import InfoIcon from '@veneer/core/dist/scripts/icons/icon_info'
import ImportUsersModal from './importUsersModal'
import { Location } from 'history'
import AddUserCard from './AddUserCard'
import SendInvites from './sendInvites'
import InviteFooter from './inviteFooter'
import { ActiveUser, User } from '../../interfaces/manageUsersInterface'
import { BreadcrumbItemType, JshellManifestBreadcrumbs, InviteUser } from '../../interfaces/inviteUsersInterface'
import { getActiveUserTenant, inviteUserWithRole } from '../../utils/api'
import { getUniqueInviteEmails, addDataTestIDtoInviteUserTable } from '../../utils/commonMethods'
import useRootContext from '../../contexts/Root/useRootContext'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import {
  UserInviteScreenDisplayed,
  SendInvitationButtonClicked,
  InvitationFailedDisplayed,
  publishEvent
} from '../../utils/analytics'
import { getCountByRole } from '../../utils/customRoles'
import { getl10n } from '../../utils/localizationMap'
import InlineNotification from '../../shared-components/InlineNotification'
import { INVITE_ERROR_CODES, INVITE_ERROR_CODE_BASE_USER, FLAGS } from '../../utils/constants'
import {
  ImportButton,
  InviteHeader,
  InviteListArea,
  PageContent,
  Container,
  Separator,
  TitleArea,
  UserCount,
  InviteAlignment,
  InfoAreaForIDP
} from './styles'
import { PermissionSet } from '../../utils/permissionSet'
import { CustomBreadcrumbType } from '../App/types'
import NavigationBlockerModal from '../../shared-components/NavigationBlockerModal'

type ModalInfoType = {
  location?: Location
  show: boolean
  unregisterCallback?(): void
}

export type InviteUserProps = {
  existingUsers: User[]
  jshellBreadcrumbs?: JshellManifestBreadcrumbs
  setBreadcrumb?: (breadcrumbs: Array<BreadcrumbItemType>) => void
  permissionSet: PermissionSet
  setCustomBreadcrumbs?: (breadcrumbs: CustomBreadcrumbType[]) => void
  useGlobalHeader?: boolean
  activeUser: ActiveUser
  rolesDataSource: string
}

const InviteUsers = ({
  existingUsers = [],
  jshellBreadcrumbs,
  permissionSet,
  setBreadcrumb,
  setCustomBreadcrumbs,
  useGlobalHeader,
  activeUser,
  rolesDataSource
}: InviteUserProps) => {
  const { addToast } = useToast()
  const { stack, shell } = useRootContext()
  const { authProvider, localization, orgSelector, navigation, breadcrumbs } = shell
  const { t } = localization.useReactTranslatorHook()

  const { featureFlagClientName, inviteUsers: inviteUsersPreferences } = usePreferencesContext()
  const { displayContextualInviteFooter, displayImportSection, displaySendInvitationSection, unsavedChangesModal } =
    inviteUsersPreferences || {}

  const { featureFlags } = shell

  const [showInfoAreaForIDP, setShowInfoAreaForIDP] = useState<boolean>(false)

  useEffect(() => {
    const getFeatureFlag = async () => {
      try {
        const client = await featureFlags.getClient(featureFlagClientName)
        const featureFlag = await client.getFeatureFlag({
          key: FLAGS.THREE_ID.KEY,
          defaultValue: FLAGS.THREE_ID.DEFAULT_VALUE
        })
        setShowInfoAreaForIDP(featureFlag)
      } catch {
        setShowInfoAreaForIDP(FLAGS.THREE_ID.DEFAULT_VALUE)
      }
    }
    getFeatureFlag()
  }, [featureFlagClientName, featureFlags])

  useEffect(() => {
    setCustomBreadcrumbs?.([
      {
        text: t(getl10n('breadcrumbHome')),
        onClick: (e) => {
          e.preventDefault()
          if (navigation) {
            navigation.push('/')
          }
        },
        url: '/'
      },
      {
        text: t(getl10n('breadcrumbUsers')),
        onClick: (e) => {
          e.preventDefault()
          if (navigation) {
            navigation.push('/users')
          }
        },
        url: '/users'
      },
      {
        text: t(getl10n('breadcrumbInviteUsers'))
      }
    ])
  }, [navigation, t, setCustomBreadcrumbs])

  const [alreadyInvitedUsers, setAlreadyInvitedUsers] = useState<Array<InviteUser>>([])
  const [excludedUsers, setExcludedUsers] = useState<Array<InviteUser>>([])
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [modal, setModal] = useState<ModalInfoType>({ show: false })
  const [showImportUsersModal, setShowImportUsersModal] = useState<boolean>(false)
  const [importedUsersText, setImportedUsersText] = useState<boolean>(false)

  const [usersToInvite, setUsersToInvite] = useState<Array<InviteUser>>([])

  useEffect(() => publishEvent(UserInviteScreenDisplayed), [])

  useEffect(() => {
    breadcrumbs.add({ key: 'invite-users', translationKey: undefined, text: 'Invite', url: undefined })
  }, [breadcrumbs])

  useEffect(() => {
    if (setBreadcrumb && jshellBreadcrumbs?.users && jshellBreadcrumbs?.invite) {
      setBreadcrumb([
        {
          ...jshellBreadcrumbs.users,
          onClick: (e) => {
            e.preventDefault()
            if (navigation) {
              navigation.push('/users')
            }
          }
        },
        jshellBreadcrumbs.invite
      ])
    }
  }, [jshellBreadcrumbs?.invite, jshellBreadcrumbs?.users, navigation, setBreadcrumb])

  useEffect(() => {
    if (navigation) {
      const unregisterCallback = navigation.block((e) => {
        if (window.location.pathname == e.pathname) {
          return false
        }

        if (isSaving || usersToInvite.length) {
          setModal((prevState) => {
            return {
              show: !prevState.show,
              location: e,
              unregisterCallback
            }
          })

          return false
        }

        return
      })

      return unregisterCallback
    }
  }, [isSaving, usersToInvite.length, navigation])

  const callToastNotifications = useCallback(
    (invites, type) => {
      function onRetry() {
        setUsersToInvite([...invites])
      }

      switch (type) {
        case 'success': {
          addToast({
            id: 'send-invitation-success',
            type: 'positive',
            action: (
              <Button appearance="ghost" small onClick={() => navigation.push('/users')}>
                {t(getl10n('invitationSucessView'))}
              </Button>
            ),
            text:
              invites.length > 1
                ? t(getl10n('invitationSuccessInvitePlural'), { num: invites.length })
                : t(getl10n('invitationSuccessInvite'))
          })
          break
        }
        case 'baseUser': {
          invites.forEach((invite, index: number) => {
            addToast({
              id: `send-invitation-failure-${index}`,
              type: 'negative',
              text: t(getl10n('invitationFailureNotEligible'), { email: invite.email }),
              action: (
                <Button data-testid="failure-toast-retry" appearance="ghost" small onClick={onRetry}>
                  {t(getl10n('retryInvite'))}
                </Button>
              )
            })
          })
          break
        }
        case 'failure': {
          const emails = invites.map(({ email }) => email).join(',')
          addToast({
            id: 'send-invitation-failure',
            type: 'negative',
            text:
              invites.length > 1
                ? t(getl10n('invitationFailureInvitePlural'), { email: emails, num: invites.length })
                : t(getl10n('invitationFailureInvite'), { email: emails }),
            action: (
              <Button data-testid="failure-toast-retry" appearance="ghost" small onClick={onRetry}>
                {t(getl10n('retryInvite'))}
              </Button>
            )
          })
          publishEvent(InvitationFailedDisplayed(invites.length))
          break
        }
      }
    },
    [addToast, navigation, t]
  )

  const callInviteUsersWithRole = useCallback(
    async (users, tenantResourceId, localization) => {
      const invitePromises = users.map((user) => {
        return inviteUserWithRole({
          email: user.email,
          roleResourceId: user.rolePermId,
          tenantResourceId,
          authProvider,
          stack,
          language: localization.language,
          country: localization.country
        })
          .then(() => {
            return { ...user, success: true }
          })
          .catch((error) => {
            const status = error?.response?.status || 0
            if (status === 400) {
              const errors = error?.response?.data?.errors || []
              const isBaseUser = !!errors.find((item) => INVITE_ERROR_CODE_BASE_USER === item.code)
              const isAlreadyInvited = !!errors.find((item) => INVITE_ERROR_CODES.includes(item.code))
              return { ...user, success: false, excluded: isAlreadyInvited, baseUser: isBaseUser }
            }
            return { ...user, success: false }
          })
      })

      const result = await Promise.all(invitePromises)
      const successInvites = result.filter(({ success }) => success)
      const failureInvites = result.filter(({ success }) => !success)

      const excludedInvites = result.filter(({ excluded }) => excluded)
      setExcludedUsers(excludedInvites as InviteUser[])

      const baseUserInvites = result.filter(({ baseUser }) => baseUser)

      if (baseUserInvites.length) {
        callToastNotifications(baseUserInvites, 'baseUser')
      }

      if (successInvites.length) {
        callToastNotifications(successInvites, 'success')
      }

      if (failureInvites.length) {
        callToastNotifications(failureInvites, 'failure')
      }

      setIsSaving(false)
      setUsersToInvite([])
    },
    [callToastNotifications, authProvider, stack]
  )

  const sendUserInvitations = useCallback(async () => {
    setIsSaving(true)
    setAlreadyInvitedUsers([])
    const activeUserTenant = await getActiveUserTenant({ orgSelector })
    const { countAdmin, countUser } = getCountByRole(permissionSet, usersToInvite)
    const distinctUsers = getUniqueInviteEmails(usersToInvite).map((user) => user)
    await callInviteUsersWithRole(distinctUsers, activeUserTenant, localization)
    if (countAdmin > 0) {
      publishEvent(SendInvitationButtonClicked('Admin', countAdmin))
    }

    if (countUser > 0) {
      publishEvent(SendInvitationButtonClicked('User', countUser))
    }
  }, [callInviteUsersWithRole, usersToInvite, orgSelector, permissionSet, localization])

  const onAddUsers = useCallback(
    (users: InviteUser[]) => {
      setExcludedUsers([])
      const usersToExclude = users.filter((invitedUser) =>
        existingUsers.find((user) => invitedUser.email === user.email)
      )
      const usersToAdd = users.filter((invitedUser) => !existingUsers.find((user) => invitedUser.email === user.email))
      const allInvitedUsersList = getUniqueInviteEmails([...usersToAdd, ...usersToInvite])
      allInvitedUsersList.forEach((value, index) => {
        value.id = index
        value.email = value.email?.toLowerCase()
      })
      setUsersToInvite(allInvitedUsersList)
      setAlreadyInvitedUsers(usersToExclude)
      setTimeout(() => {
        addDataTestIDtoInviteUserTable()
      }, 100)
    },
    [existingUsers, usersToInvite]
  )

  const updateUsers = (newEmail: string, newRole: string, id: number) => {
    usersToInvite.forEach((user) => {
      if (user.id === id) {
        user.email = newEmail
        user.rolePermId = newRole
      }
    })
    setUsersToInvite([...usersToInvite])
  }

  const onDelete = useCallback(
    (id: number) => {
      const userEmails = usersToInvite.filter((user: InviteUser) => user.id !== id)
      setUsersToInvite(userEmails)
    },
    [usersToInvite]
  )

  const getImportedUsers = useCallback(
    async (value) => {
      const userDataImp = []
      value
        .filter((item) => item.role === 'IT Admin' || item.role === 'End User')
        .map((item) => {
          const permId = permissionSet.filter((role) => {
            return role.label === item.role
          })
          const user = {
            email: item.email,
            rolePermId: permId[0].rolePermId
          }
          return user
        })

        .forEach((item) => {
          userDataImp.push(item)
        })
      onAddUsers(userDataImp)
      setImportedUsersText(true)
      setShowImportUsersModal(false)
    },
    [onAddUsers, permissionSet]
  )

  const getInlineNotification = useCallback((type, title, invites, onClose) => {
    if (invites && invites.length) {
      const description = (
        <ul>
          {invites.map((user, index) => (
            <li className="caption-small" key={`inline-notification-item-${index}`} data-testid={`email-${index}`}>
              {user.email}
            </li>
          ))}
        </ul>
      )
      return (
        <InlineNotification
          data-testid="email-warning"
          title={title}
          type={type}
          description={description}
          onClose={onClose}
        />
      )
    }
  }, [])

  const getInviteUserInformation = useCallback(
    () =>
      getInlineNotification('informative', t(getl10n('invitationNotificationInformation')), alreadyInvitedUsers, () =>
        setAlreadyInvitedUsers([])
      ),
    [alreadyInvitedUsers, getInlineNotification, t]
  )

  const getInviteUserWarning = useCallback(
    () =>
      getInlineNotification('warning', t(getl10n('invitationNotificationWarning')), excludedUsers, () =>
        setExcludedUsers([])
      ),
    [excludedUsers, getInlineNotification, t]
  )

  return (
    <Container>
      {!useGlobalHeader && (
        <TitleArea className="header">
          <div className="title-small page-title" data-testid="page-invite-title">
            {t(getl10n('inviteOverlayTitle'))}
          </div>
          <div className="body page-description" data-testid="page-invite-description">
            {t(getl10n('inviteOverlayDescription'))}
          </div>
        </TitleArea>
      )}

      <PageContent data-testid="page-content">
        <AddUserCard
          permissionSet={permissionSet}
          onAddUsers={onAddUsers}
          disableTableActions={isSaving}
          activeUser={activeUser}
          rolesDataSource={rolesDataSource}
        />

        <InviteListArea>
          {displaySendInvitationSection && (
            <>
              <InviteHeader>
                <UserCount className="label" data-testid="total-recipients-label">
                  {t(getl10n('recipient'))}
                  <span>{'  |  '}</span>
                  {usersToInvite.length}
                </UserCount>
                <Button
                  data-testid="send-invitation-button"
                  disabled={!usersToInvite.length}
                  id="send-invitations"
                  leadingIcon={<IconPaperAirplane />}
                  loading={isSaving}
                  onClick={sendUserInvitations}
                >
                  {t(getl10n('inviteOverlaySendInvite'))}
                </Button>
              </InviteHeader>

              <Separator />

              {getInviteUserInformation()}
              {getInviteUserWarning()}
            </>
          )}

          {displayImportSection && (
            <div className="import-user-section">
              {showInfoAreaForIDP && (
                <InfoAreaForIDP>
                  <InfoIcon color="gray6" filled />
                  <span>{t(getl10n('thirdPartyLabel'))}</span>
                </InfoAreaForIDP>
              )}
              <InviteAlignment>
                <UserCount className="label" data-testid="total-recipients-label">
                  {importedUsersText
                    ? usersToInvite.length + ` ${t(getl10n('usersImportedTitle'))}`
                    : usersToInvite.length === 0 || usersToInvite.length === 1
                    ? usersToInvite.length + ` ${t(getl10n('addUserTitle'))}`
                    : usersToInvite.length + ` ${t(getl10n('addUsersTitle'))}`}
                </UserCount>
                <ImportButton
                  appearance="secondary"
                  className="pull-right-import"
                  data-testid="import-button"
                  id="import-button"
                  small
                  onClick={() => setShowImportUsersModal(true)}
                >
                  {t(getl10n('importLabel'))}
                </ImportButton>
              </InviteAlignment>
            </div>
          )}

          <SendInvites
            disableTableActions={isSaving}
            onUserDelete={onDelete}
            onUsersUpdate={updateUsers}
            usersToInvite={usersToInvite}
            permissionSet={permissionSet}
            activeUser={activeUser}
            rolesDataSource={rolesDataSource}
          />
        </InviteListArea>
      </PageContent>

      <ImportUsersModal
        data-testid="import-users-modal"
        importedUsers={(value) => getImportedUsers(value)}
        modalTitle={t(getl10n('userimportLabel'))}
        onClose={() => setShowImportUsersModal(false)}
        showModal={showImportUsersModal}
      />

      <NavigationBlockerModal
        data-testid="navigation-blocker-modal"
        modalTitle={t(getl10n('userSettingsModalTitle'))}
        onClose={() => {
          setModal((prev) => ({
            ...prev,
            show: false,
            location: undefined
          }))
        }}
        onConfirm={() => {
          const { unregisterCallback, location } = modal || {}
          unregisterCallback()
          navigation.push(location)
          setModal({ show: false })
          navigation.push('/users')
        }}
        showModal={!!modal?.show}
        usersToInviteLength={usersToInvite.length}
        customModalWidth={unsavedChangesModal.modalWidth}
        customModalDescWidth={unsavedChangesModal.bodyTextWidth}
      />

      <InviteFooter
        closeInvitePage={() => navigation.push('/users')}
        data-testid="invite-footer-contextualmenu"
        isSaving={isSaving}
        sendUserInvitations={sendUserInvitations}
        setUserstoInvite={setUsersToInvite}
        usersToInvite={usersToInvite}
        show={displayContextualInviteFooter && usersToInvite.length > 0}
      />
    </Container>
  )
}

export default InviteUsers
