import { ColumnIndexTypes, TablePreferences } from '@veneer/core/dist/scripts/table'
import { getl10n } from './localizationMap'
import { TranslatorFunctionType } from '../types/shell'

export type UserListColumnType = {
  id: string
  labelKey: string
  label: string
  index?: ColumnIndexTypes
  sortable?: boolean
  required?: boolean
  width?: string
}

export const initUserListColumns = (list: Array<UserListColumnType> = [], t: TranslatorFunctionType) => {
  if (list.length === 0) {
    console.error('UserListColumn configuration not initialized correctly in MFE properties. List: ', list)
    return []
  }
  const userListColumns = [...list]
  userListColumns.forEach((col) => {
    if (!col.label && col.labelKey) {
      col.label = t(getl10n(col.labelKey))
    }
  })

  return userListColumns
}

export const UserListConstants = {
  ascending: 'ascending',
  assignRole: 'assignRole',
  currentPage: 1,
  edit: 'Edit',
  fullName: 'fullName',
  name: 'name',
  pageSize: 25,
  removeUser: 'removeUser',
  resendInvitation: 'resendInvitation',
  rowConfig: 'rowConfig',
  state: 'state',
  status: 'status',
  email: 'email',
  emailLabel: 'emailLabel',
  role: 'role',
  roleLabel: 'roleLabel'
}

export const UserListPreferences: TablePreferences = {
  width: [
    {
      columnId: 'name',
      width: 80
    },
    {
      columnId: 'email',
      width: 105
    },
    {
      columnId: 'role',
      width: 105
    },
    {
      columnId: 'status',
      width: 105
    }
  ]
}
