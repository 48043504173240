import { OptionInterface } from '@veneer/core/dist/scripts/contextual_menu'
// import { capitalizeString } from './commonMethods'

/*
 * Definitions over this doc: https://emailregex.com/
 */
export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
) // eslint-disable-line

/*
 * Definitions over this doc: https://emailregex.com/
 */
export const DOMAIN_REGEX = new RegExp(
  /^@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
) // eslint-disable-line

export const INVITES_MAX_LENGTH = 50

export const EMAIL_ALREADY_EXIST_CODE = 'E0010U0019'
export const INVITE_ERROR_CODES = ['E0010U0044', 'EB000U0120', 'E0010U0045', 'EB000U0121', 'E0010U0046', 'EB000U0122']
export const INVITE_ERROR_CODE_BASE_USER = 'EB000A0124'

export const tabOptions = [
  {
    id: 0,
    label: 'All Users',
    content: ' '
  }
]

export const defaultPageSizeOptions: OptionInterface<number>[] = [
  { value: 25 },
  { value: 50 },
  { value: 75 },
  { value: 100 }
]

export const SettingsOptions = [
  { value: 'HP', label: 'HP' },
  { value: 'Google', label: 'Google' },
  { value: 'Azure', label: 'Azure' }
]

export const UserStatus = [
  { value: 'Active', label: 'statusActive' },
  { value: 'Expired', label: 'statusExpired' },
  { value: 'Pending', label: 'statusPending' }
]

export const STORAGE_COLUMN_USER = 'user-management-configured-column'
export const STORAGE_COLUMN_USER_ECP = 'user-management-configured-column-ecp'

export const SearchParamValues = {
  Admin: 'admin',
  ITAdmin: 'it admin',
  EndUser: 'end user',
  EndUserSearchValue: 'enduser'
}

export const StatusValues = UserStatus.map((item) => item.value.toLowerCase())

export const sample = [
  {
    email: 'Test1@yopmail.com',
    role: 'IT Admin'
  },
  {
    email: 'Test2@yopmail.com',
    role: 'IT Admin'
  },
  {
    email: 'Test3@yopmail.com',
    role: 'IT Admin'
  }
]

export const headers = [
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'role',
    key: 'role'
  }
]

export const ACCOUNT_MGT_USERS_WRITE_SCOPE = [{ scope: 'ws-hp.com/accountmgtsvc/users.WRITE' }]

export const ACCOUNT_USER_MGT_WRITE_ASSIGNROLE_SCOPE = [
  { scope: 'ws-hp.com/accountmgtsvc/users.WRITE' },
  { scope: 'ws-hp.com/usermgtsvc/users.WRITE' }
]

export const ACCOUNT_MGT_USERS_DELETE_SCOPE = [{ scope: 'ws-hp.com/accountmgtsvc/users.DELETE' }]

export const FLAGS = {
  THREE_ID: {
    KEY: 'ecp-3id',
    DEFAULT_VALUE: false
  }
}

export const EN_US = 'en-US'

export const ACTION_PUSH = 'push'
export const ACTION_POP = 'pop'
export const ACTION_CLEARALL = 'clearall'
