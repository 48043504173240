import React, { useCallback, useEffect, useState } from 'react'
import { MicroFrontend } from '@jarvis/react-mfe-component'
import useRootContext from '../../contexts/Root/useRootContext'
import { getl10n } from '../../utils/localizationMap'
export const HeaderBanner = (props) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const { navigation } = shell
  const [titleText, setTitleText] = useState<string>('')
  const [disableReload, setDisableReload] = useState<boolean>(false)

  const userTitle = () => {
    setTitleText(t(getl10n('pageHeader')))
    setDisableReload(false)
  }

  const inviteUserTitle = () => {
    setTitleText(t(getl10n('inviteOverlayTitle')))
    setDisableReload(true)
  }

  const handleHeaderTitle = () => {
    navigation.location.pathname.includes('/users/invite') ? inviteUserTitle() : userTitle()
  }

  useEffect(() => {
    handleHeaderTitle()
  })

  const SubMfe = useCallback(
    ({ component, title, disableReload, type }) => {
      return (
        <MicroFrontend
          disableContainerPadding
          {...props}
          {...{
            component,
            title,
            disableReload,
            type
          }}
        />
      )
    },
    [props]
  )
  return (
    <SubMfe type="headerTitle" title={titleText} component="@jarvis/react-ecp-header" disableReload={disableReload} />
  )
}
