import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Warning = styled.p`
  color: ${primitives.color.gray10};
  display: flex;
  font-size: 14px;
  margin-top: 10px;

  > div {
    margin-right: 6px;
  }
`

export default Warning
