import React, { useMemo } from 'react'
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider'
import ThemeProvider from '../../shared-components/ThemeProvider'
import RootContext, { RootContext as RootContextType } from './RootContext'
import PreferencesProvider from '../Preferences/PreferencesProvider'
import { PreferencesContextProps } from '../Preferences/PreferencesContext'

type Resource = { [key: string]: Resource | string }

type RootProviderProps = {
  children: React.ReactNode
  value: RootContextType
  preferences: PreferencesContextProps
  resources: Resource
}

const RootProvider = ({ children, value, preferences, resources }: RootProviderProps) => {
  const { shell } = value
  const { localization } = shell
  const { density } = preferences

  const TranslatorProvider = useMemo(() => localization.getReactTranslatorProvider(React), [localization])

  return (
    <RootContext.Provider value={value}>
      <PreferencesProvider value={preferences}>
        <TranslatorProvider resources={resources}>
          <ThemeProvider density={density}>
            <ToastProvider>{children}</ToastProvider>
          </ThemeProvider>
        </TranslatorProvider>
      </PreferencesProvider>
    </RootContext.Provider>
  )
}

export default RootProvider
