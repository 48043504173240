import React from 'react'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import useRootContext from '../../../contexts/Root/useRootContext'
import { getl10n } from '../../../utils/localizationMap'
import GenericModal from '../GenericModal'
import './index.scss'

const RemoveUserModal = ({
  forFilterScreen,
  hideContentModalRemoveUser,
  modalContent,
  modalTitle,
  modalSubcontent,
  onClose,
  onDelete,
  showModal,
  isLoading
}) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  return (
    <GenericModal
      dataTestId={forFilterScreen ? 'delete-filter-modal' : 'regular-modal'}
      id={forFilterScreen ? 'delete-filter-modal' : 'regular-modal'}
      onClose={onClose}
      show={showModal}
      header={{
        leadingIcon: <IconWarningAlt color="darkOrange6" filled={true} size={48} />,
        title: modalTitle,
        dataTestId: 'remove-modal-title'
      }}
      footer={{
        buttons: {
          cancel: {
            dataTestId: forFilterScreen ? 'delete-filter-modal-cancel-button' : 'remove-modal-cancel-btn',
            label: t(getl10n('columnModalCancel')),
            onClick: onClose,
            disabled: isLoading
          },
          proceed: {
            colorScheme: 'negative',
            dataTestId: forFilterScreen ? 'delete-filter-modal-delete-button' : 'remove-button',
            label: forFilterScreen ? 'Delete' : t(getl10n('modalRemove')),
            onClick: onDelete,
            disabled: isLoading,
            loading: isLoading
          }
        }
      }}
    >
      <>
        {!hideContentModalRemoveUser && <div data-testid="remove-modal-content">{modalContent}</div>}
        {!modalSubcontent ? (
          <div data-testid="remove-modal-description">{t(getl10n('useRemoveModalContent'))}</div>
        ) : (
          <div className="content-subtitle">{t(getl10n('removeUserSubContent'))}</div>
        )}
      </>
    </GenericModal>
  )
}

export default RemoveUserModal
