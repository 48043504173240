import React from 'react'
import IconWarningAlt from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import useRootContext from '../../contexts/Root/useRootContext'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import { getl10n } from '../../utils/localizationMap'
import { Container, IconContainer } from './styles'

const NoItemsWarning = () => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  const { inviteUsers: inviteUsersPreferences } = usePreferencesContext()
  const { addUsers: addUsersPreferences } = inviteUsersPreferences || {}
  const { displayNoEmailMessage } = addUsersPreferences || {}

  return (
    <Container>
      <IconContainer>
        <IconWarningAlt size={24} />
      </IconContainer>
      {displayNoEmailMessage && (
        <p data-testid="no-emails-item" className="label">
          {t(getl10n('noEmailLabel'))}
        </p>
      )}
      <p data-testid="no-invite-item" className="body">
        {t(getl10n('inviteOverlayNoEmail'))}
      </p>
    </Container>
  )
}

export default NoItemsWarning
