import React from 'react'
import IconInfo from '@veneer/core/dist/scripts/icons/icon_info'
import { Warning } from './styles'
import useRootContext from '../../contexts/Root/useRootContext'

export const RoleWarningInfo = () => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  return (
    <Warning>
      <div>
        <IconInfo filled color="gray5" size={24} />
      </div>
      <p data-testid="add-user-admin-role-label">{t('manageUsers.assignRole.body.option1')}</p>
    </Warning>
  )
}

export default RoleWarningInfo
