import React from 'react'
import GenericModal from '../GenericModal'
import useRootContext from '../../../contexts/Root/useRootContext'
import { getl10n } from '../../../utils/localizationMap'

const ResendInvitationModal = ({ isLoading, onClose, onConfirm, show }) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  return (
    <GenericModal
      dataTestId="resend-invitation-modal"
      id="resend-invitation-modal"
      onClose={onClose}
      show={show}
      header={{
        dataTestId: 'resend-invitation-modal-title',
        title: t(getl10n('actionAssignResendInvitation'))
      }}
      footer={{
        buttons: {
          cancel: {
            dataTestId: 'resend-invitation-cancel-button',
            label: t(getl10n('modalCancel')),
            onClick: onClose,
            disabled: isLoading
          },
          proceed: {
            dataTestId: 'resend-invitation-confirm-button',
            disabled: isLoading,
            label: t(getl10n('inviteOverlaySendInvite')),
            onClick: onConfirm,
            loading: isLoading
          }
        }
      }}
    >
      <p className="body" data-testid="resend-invitation-description">
        {t(
          getl10n('invitationDescription'),
          'This users will receive a new email invitation to use the printers paired with this account.'
        )}
      </p>
    </GenericModal>
  )
}

export default ResendInvitationModal
