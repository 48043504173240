import styled from 'styled-components'
import primitives from '@veneer/primitives'
import VeneerButtonGroup from '@veneer/core/dist/scripts/button_group'
import modal from '@veneer/core/dist/scripts/modal'
import { mixins } from '@jarvis/react-portal-addons'

export const StyledModal = styled(modal)`
  .vn-modal--content {
    width: ${({ customModalWidth }) => customModalWidth};

    ${mixins.xs(`
      width: auto;
    `)}
  }
  .body-large {
    width: ${({ customModalDescWidth }) => customModalDescWidth};

    ${mixins.xs(`
      width: auto;
    `)}
  }

  .vn-modal--footer {
    justify-content: flex-end;
  }
`

export const ButtonGroup = styled(VeneerButtonGroup)`
  justify-content: flex-end;
`

export const ModalHeader = styled.div`
  align-items: flex-start;
  column-gap: ${primitives.layout.size2}px;
  display: flex;
  margin-bottom: ${primitives.layout.size5}px;
`

export const ModalHeaderIcon = styled.div`
  flex: 0 0 auto;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    width: 100%;
  }

  @media (max-width: ${primitives.layout.breakpoint4}px) {
    height: 32px;
    width: 32px;
  }
`
