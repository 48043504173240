import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ paddingTop }) => (paddingTop ? '24px' : '0 24px')};

  @media screen and (max-width: ${primitives.layout.breakpoint1}px) {
    padding: ${({ mobilePaddingTop }) => (mobilePaddingTop ? '20px 20px 0' : '0 20px')};
  }
`
