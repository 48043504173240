import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: ${primitives.layout.cornerRadius3}px;
  margin-bottom: ${primitives.layout.size5}px;
  padding: 0 ${primitives.layout.size1}px 0 ${primitives.layout.size3}px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  > svg {
    margin-right: ${primitives.layout.size2}px;
    margin-top: ${primitives.layout.size3}px;
  }
  > button {
    margin-top: ${primitives.layout.size1}px;
  }

  &.informative {
    background-color: #5858581a;
    > svg {
      fill: ${({ theme }) => theme.color.foreground.default};
    }
  }

  &.warning {
    background-color: #d067021a;
    > svg {
      fill: ${primitives.color.darkOrange5};
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin: ${primitives.layout.size3}px ${primitives.layout.size3}px ${primitives.layout.size3}px
    ${primitives.layout.size1}px;
  word-break: break-word;
`

export const Description = styled.span`
  > ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`
