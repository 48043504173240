import React, { useMemo } from 'react'
import { ContextualToolbar } from '@jarvis/react-portal-addons'
import { getl10n } from '../../utils/localizationMap'
import useRootContext from '../../contexts/Root/useRootContext'

const InviteFooter = ({ closeInvitePage, isSaving, sendUserInvitations, setUserstoInvite, show, usersToInvite }) => {
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()

  const buttons = useMemo(
    () => ({
      apply: {
        appearance: 'primary',
        'data-testid': 'invite-button',
        disabled: !usersToInvite.length,
        loading: isSaving,
        onClick: sendUserInvitations,
        label: t(getl10n('breadcrumbInviteUsers')),
        small: true
      },
      cancel: {
        appearance: 'secondary',
        'data-testid': 'cancel-button',
        id: 'cancelBtn',
        label: t(getl10n('contextualFooterCancel')),
        onClick: () => {
          setUserstoInvite([])
          closeInvitePage()
        },
        small: true
      }
    }),
    [closeInvitePage, isSaving, sendUserInvitations, setUserstoInvite, t, usersToInvite.length]
  )

  return <ContextualToolbar backgroundColor="#ebf9ff" buttons={buttons} show={show} variant="confirm" />
}

export default InviteFooter
