import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { Button } from '@veneer/core'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PageContent = styled.div`
  display: flex;
  gap: ${primitives.layout.size6}px;

  > * :first-child {
    flex: 2 2 auto;
    width: 100%;
    max-width: 538px;
    min-width: 378px;

    @media screen and (max-width: ${primitives.layout.breakpoint6}px) {
      flex: 1 auto;
      max-width: 100%;
      min-width: auto;
    }
  }

  > * :last-child {
    flex: 1 auto;
    width: 100%;
  }

  @media screen and (max-width: ${primitives.layout.breakpoint6}px) {
    flex-direction: column;
  }
`

export const InviteSuccessHeader = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;
    color: ${primitives.color.hpBlue7};
  }

  label {
    padding-left: 11px;
  }
`
export const InviteSuccessBody = styled.div`
  width: 516px;
  padding-top: 20px;
  display: flex;
  font-weight: 400;
`
export const InviteSuccessFooter = styled.div``

export const AddButton = styled(Button)`
  && {
    min-width: ${({ small }) => (small ? '66px' : '112px')};
    align-self: flex-end;
  }
`
export const ImportButton = styled(Button)`
  && {
    min-width: ${({ small }) => (small ? '88px' : 'initial')};
  }
`

export const EditModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const UserCount = styled.div`
  padding: 14px ${primitives.layout.size2}px;
  color: ${({ theme }) => theme.color.foreground.default};
`

export const InviteListArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Separator = styled.div`
  border-top: 1px solid ${primitives.color.gray5};
`

export const InviteItemTooltip = styled.label`
  display: block;
  max-width: 289px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const InviteHeader = styled.div`
  align-content: space-around;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`
export const InviteAlignment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`
export const InfoAreaForIDP = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: ${primitives.layout.size3}px;
  border-radius: ${primitives.layout.cornerRadius3}px;
  background-color: #5858581a;
  svg {
    margin: 8px;
  }
  span {
    margin-top: 8px;
  }
`
