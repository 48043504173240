import React, { useCallback, useEffect, useState } from 'react'
import Select from '@veneer/core/dist/scripts/select'
import TextBox from '@veneer/core/dist/scripts/text_box'
import { EditUserState } from '../../interfaces/inviteUsersInterface'
import GenericModal from '../../shared-components/Modals/GenericModal'
import { EMAIL_REGEX } from '../../utils/constants'
import useRootContext from '../../contexts/Root/useRootContext'
import { getl10n } from '../../utils/localizationMap'
import { RoleWarningInfo } from '../../shared-components/RoleWarningInfo'
import { PermissionSet, getSelectablePermissionSet } from '../../utils/permissionSet'
import { isShowRoleMessage } from '../../utils/commonMethods'
import usePreferencesContext from '../../contexts/Preferences/usePreferencesContext'
import { EditModalContainer } from './styles'
import { ActiveUser } from '../../interfaces/manageUsersInterface'

export type EditUserProps = {
  email: string
  handleClose: () => void
  id: number
  onUserUpdate: (newEmail: string, newRolePermId: string, id: number) => void
  rolePermId: string
  permissionSet: PermissionSet
  activeUser: ActiveUser
  rolesDataSource: string
}

const EditUser = ({
  email,
  rolePermId,
  onUserUpdate,
  handleClose,
  id,
  permissionSet,
  rolesDataSource
}: EditUserProps) => {
  const [editUserForm, setEditUserForm] = useState<EditUserState>({
    email,
    errorMessage: null,
    hasChanges: false,
    rolePermId
  })
  const { shell } = useRootContext()
  const { localization } = shell
  const { t } = localization.useReactTranslatorHook()
  const [showRoleMessage, setShowRoleMessage] = useState<boolean>(false)

  const { displayAdminRoleWarning } = usePreferencesContext()

  useEffect(() => {
    setShowRoleMessage(isShowRoleMessage(displayAdminRoleWarning, permissionSet, rolePermId))
  }, [displayAdminRoleWarning, permissionSet, rolePermId])

  const validateEmail = useCallback(
    (email: string) => {
      const invalidEmailMessage = !EMAIL_REGEX.test(email) ? t(getl10n('editUserEmailError')) : ''
      const errorMessage = invalidEmailMessage

      setEditUserForm({
        ...editUserForm,
        email,
        hasChanges: !errorMessage,
        errorMessage: errorMessage || null
      })
    },
    [editUserForm, t]
  )

  const handleRoleChange = useCallback(
    ({ value: rolePermId }) => {
      setEditUserForm({
        ...editUserForm,
        hasChanges: true,
        rolePermId
      })
      setShowRoleMessage(isShowRoleMessage(displayAdminRoleWarning, permissionSet, rolePermId))
    },
    [displayAdminRoleWarning, editUserForm, permissionSet]
  )

  const saveChanges = useCallback(() => {
    onUserUpdate(editUserForm.email, editUserForm.rolePermId, id)
  }, [editUserForm.email, editUserForm.rolePermId, id, onUserUpdate])

  const getOptions = () => {
    let getSortedData
    if (rolesDataSource !== 'api') {
      getSortedData = getSelectablePermissionSet(permissionSet)
    } else {
      getSortedData = [...permissionSet].sort((a, b) => a.label.localeCompare(b.label))
    }
    return getSortedData.map((ps) => ({
      label: ps.label,
      value: ps.rolePermId
    }))
  }

  return (
    <GenericModal
      dataTestId="edit-user-modal"
      id="edit-user-modal"
      onClose={handleClose}
      show
      header={{
        dataTestId: 'edit-user-modal-title',
        title: t(getl10n('editModalTitle'))
      }}
      footer={{
        buttons: {
          cancel: {
            label: t(getl10n('columnModalCancel')),
            onClick: handleClose,
            dataTestId: 'close-button'
          },
          proceed: {
            disabled: !editUserForm.hasChanges || Boolean(editUserForm.errorMessage),
            label: t(getl10n('modalSave')),
            onClick: saveChanges,
            dataTestId: 'save-changes-button'
          }
        }
      }}
    >
      <EditModalContainer>
        <div>
          <div id="edit-user-text" className="label" data-testid="edit-user-text">
            {t(getl10n('emailAddress'))}
          </div>

          <TextBox
            type="text"
            id="edit-text-box"
            className="edit-user-text-box"
            name="email"
            value={editUserForm.email}
            onChange={(e) => validateEmail(e.toString())}
            error={Boolean(editUserForm.errorMessage)}
            helperText={editUserForm.email ? editUserForm.errorMessage : t(getl10n('errorBlankText'))}
            data-testid="edit-user-text-box"
          />
        </div>

        <div>
          <label className="label" data-testid="role-label">
            {t(getl10n('role'))}
          </label>

          <Select
            className="edit-user-select"
            value={[editUserForm.rolePermId]}
            clearIcon={false}
            label={t(getl10n('addUserSelectLabel'))}
            onChange={handleRoleChange}
            options={getOptions()}
            data-testid="edit-user-select"
          />
          {showRoleMessage && <RoleWarningInfo />}
        </div>
      </EditModalContainer>
    </GenericModal>
  )
}

export default EditUser
